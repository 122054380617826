/* ========== MAGNIFIC POPAP ======
========================================= */

.modal-content {
    background: #fff;
    padding: 30px;
    position: relative;
    margin: 0 auto;
    max-width: 450px;
}
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: rgb(0, 0, 0);
    opacity: 0.75;
    filter: alpha(opacity=75);
}
.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}
.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
    display: none;
}
.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}
.white-block {
    background: #fff;
    margin: 0 auto;
    padding: 30px;
    position: relative;
    max-width: 500px;
}
.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}
.mfp-ajax-cur {
    cursor: progress;
}
.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}
.fa.close-popap-btn {
    color: #29b6f6;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    right: 12px;
    top: 10px;
    margin: 0
}
.fa.close-popap-btn:hover {
    color: #555
}
.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}
.mfp-auto-cursor .mfp-content {
    cursor: auto;
}
.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.mfp-loading.mfp-figure {
    display: none;
}
.mfp-hide {
    display: none;
}
.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}
.mfp-preloader a {
    color: #CCC;
}
.mfp-preloader a:hover {
    color: #FFF;
}
.mfp-s-ready .mfp-preloader {
    display: none;
}
.mfp-s-error .mfp-content {
    display: none;
}
button.mfp-close, button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}
.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    filter: alpha(opacity=65);
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover, .mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100);
}
.mfp-close-btn-in .mfp-close {
    color: #333;
}
.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
}
.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}
.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    filter: alpha(opacity=65);
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent;
}
.mfp-arrow:active {
    margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100);
}
.mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
}
.mfp-arrow:after, .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}
.mfp-arrow:before, .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}
.mfp-arrow-left {
    left: 0;
}
.mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
    border-right: 17px solid #FFF;
    margin-left: 31px;
}
.mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
}
.mfp-arrow-right {
    right: 0;
}
.mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
    border-left: 17px solid #FFF;
    margin-left: 39px;
}
.mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
    border-left: 27px solid #3F3F3F;
}
.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
    top: -40px;
}
.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}
.white-popup-block {
    background: #fff;
    padding: 30px;
    position: relative;
    max-width: 500px;
    margin: 0 auto
}

/* Main image in popup */

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}

/* The shadow behind the image */

.mfp-figure {
    line-height: 0;
}
.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
}
.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
}
.mfp-figure figure {
    margin: 0;
}
.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}
.mfp-title {
    text-align: center;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    margin-top: 5px;
}
.mfp-image-holder .mfp-content {
    max-width: 100%;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}
@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    /**
       * Remove all paddings around the image on small screen
       */
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }
    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }
    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }
    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }
    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }
    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }
    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0;
    }
}
@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        -moz-transform: scale(0.75);
        -ms-transform: scale(0.75);
        -o-transform: scale(0.75);
        transform: scale(0.75);
    }
    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        -moz-transform-origin: 0;
        -ms-transform-origin: 0;
        -o-transform-origin: 0;
        transform-origin: 0;
    }
    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        -moz-transform-origin: 100%;
        -ms-transform-origin: 100%;
        -o-transform-origin: 100%;
        transform-origin: 100%;
    }
    .mfp-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.mfp-ie7 .mfp-img {
    padding: 0;
}
.mfp-ie7 .mfp-bottom-bar {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    margin-top: 5px;
    padding-bottom: 5px;
}
.mfp-ie7 .mfp-container {
    padding: 0;
}
.mfp-ie7 .mfp-content {
    padding-top: 44px;
}
.mfp-ie7 .mfp-close {
    top: 0;
    right: 0;
    padding-top: 0;
}
.mfp-preloader {
    background: url(../images/icons/preloader.gif) center no-repeat #0B0B0B;
    text-indent: -999px;
    overflow: hidden;
    margin: 0 auto;
    height: 80px;
    width: 80px;
}

/**
 * Simple Scale transition,
 */

.mfp-scale.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
}
.mfp-scale.mfp-bg.mfp-ready {
    opacity: 0.95;
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.mfp-scale.mfp-bg.mfp-removing {
    opacity: 0;
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
}
.mfp-scale.mfp-wrap .mfp-content {
    opacity: 0;
    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    -o-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
}
.mfp-scale.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
.mfp-scale.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    transform: scale(1.3);
}

/**
 * Bottom Up transition,
 */

.mfp-bottom-up.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.mfp-bottom-up.mfp-bg.mfp-ready {
    opacity: 0.4;
    transition: all 0.3s ease-out;
}
.mfp-bottom-up.mfp-bg.mfp-removing {
    transition: all 0.3s ease-out;
    opacity: 0;
}
.mfp-bottom-up.mfp-wrap .mfp-content {
    opacity: 0;
    -webkit-transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    -moz-transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    -o-transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    top: 100%;
}
.mfp-bottom-up.mfp-wrap.mfp-ready .mfp-content {
    transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    opacity: 1;
    top: 0;
}
.mfp-bottom-up.mfp-wrap.mfp-removing .mfp-content {
    transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    opacity: 0;
    top: 100%;
}

/**
 * Top Down transition,
 */

.mfp-top-up.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.mfp-top-up.mfp-bg.mfp-ready {
    opacity: 0.4;
    transition: all 0.3s ease-out;
}
.mfp-top-up.mfp-bg.mfp-removing {
    transition: all 0.3s ease-out;
    opacity: 0;
}
.mfp-top-up.mfp-wrap .mfp-content {
    opacity: 0;
    -webkit-transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    -moz-transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    -o-transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    bottom: 100%;
}
.mfp-top-up.mfp-wrap.mfp-ready .mfp-content {
    transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    opacity: 1;
    bottom: 0;
}
.mfp-top-up.mfp-wrap.mfp-removing .mfp-content {
    transition: all 0.5s cubic-bezier(.25, .98, .26, .99);
    opacity: 0;
    bottom: 100%;
}

/**
 * Fade-zoom animation for first dialog
 */


/* start state */

.my-mfp-zoom-in .zoom-anim-dialog {
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
}

/* animate in */

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

/* animate out */

.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
}

/* Dark overlay, start state */

.my-mfp-zoom-in.mfp-bg {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}

/* animate in */

.my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8;
}

/* animate out */

.my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0;
}

/**
 * Fade-move animation for second dialog
 */


/* at start */

.my-mfp-slide-bottom .zoom-anim-dialog {
    opacity: 0;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -webkit-transform: translateY(-20px) perspective( 600px) rotateX( 10deg);
    -moz-transform: translateY(-20px) perspective( 600px) rotateX( 10deg);
    -ms-transform: translateY(-20px) perspective( 600px) rotateX( 10deg);
    -o-transform: translateY(-20px) perspective( 600px) rotateX( 10deg);
    transform: translateY(-20px) perspective( 600px) rotateX( 10deg);
}

/* animate in */

.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: translateY(0) perspective( 600px) rotateX( 0);
    -moz-transform: translateY(0) perspective( 600px) rotateX( 0);
    -ms-transform: translateY(0) perspective( 600px) rotateX( 0);
    -o-transform: translateY(0) perspective( 600px) rotateX( 0);
    transform: translateY(0) perspective( 600px) rotateX( 0);
}

/* animate out */

.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
    opacity: 0;
    -webkit-transform: translateY(-10px) perspective( 600px) rotateX( 10deg);
    -moz-transform: translateY(-10px) perspective( 600px) rotateX( 10deg);
    -ms-transform: translateY(-10px) perspective( 600px) rotateX( 10deg);
    -o-transform: translateY(-10px) perspective( 600px) rotateX( 10deg);
    transform: translateY(-10px) perspective( 600px) rotateX( 10deg);
}

/* Dark overlay, start state */

.my-mfp-slide-bottom.mfp-bg {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}

/* animate in */

.my-mfp-slide-bottom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

/* animate out */

.my-mfp-slide-bottom.mfp-removing.mfp-bg {
    opacity: 0;
}

/** Modal Form **/

.modal-content {
    position: relative;
}
.modal-content h4 {
    margin-top: 10px;
    text-align: center;
}
.modal-content .form-row {
    margin-bottom: 30px;
}
.modal-content .inputbox {
    background-color: #f2f2f2;
    color: #666;
    height: 50px;
    width: 100%
}
.modal-content .textbox {
    background-color: #f2f2f2;
    color: #555;
    padding: 10px 15px;
    height: 150px;
    width: 100%
}
.modal-content .inputbox::-webkit-input-placeholder {
    color: #666
}
.modal-content .inputbox::-moz-placeholder {
    color: #666
}
.modal-content .textbox::-webkit-input-placeholder {
    color: #666
}
.modal-content .textbox::-moz-placeholder {
    color: #666
}
.modal-content .form-row:last-child {
    margin-bottom: 0
}
.modal-content .btn-block {
    margin-bottom: 10px
}
.modal-content .success-text {
    color: #333
}
.modal-content .rsform-submit-button::before {
    content: "\f0a4";
    display: inline-block;
    font: normal normal normal 20px/1 FontAwesome;
    margin-right: 7px;
}
