hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eeeeee;
    height: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}
.well {
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    padding: 25px;
    margin-bottom: 20px;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
}
.caret {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: top;
    border-top: 4px solid #000;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: "";
}
.btn .caret {
    margin-top: 13px;
    margin-left: 5px;
}
.btn-group {
    position: relative;
    display: inline-block;
    font-size: 0;
    vertical-align: middle;
    white-space: nowrap;
}
.element-invisible {
    display: none;
}

/*********** Html5 tags ************/

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}
audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden], template {
    display: none;
}
fieldset, img {
    border: none
}
img {
    max-width: 100%;
    height: auto;
}
figure {
    margin: 1em 40px;
}
.star, .red {
    color: red
}
.color-blue {
    color: #29b6f6
}
address {
    font-style: normal;
}

/** Form elements **/

legend {
    display: block;
    margin: 0 0 30px;
    font-size: 24px;
}
input, button, textarea {
    outline: none;
}
.control-group:not(:last-child) {
    margin-bottom: 15px;
}
.control-label {
    color: #444444;
    font-size: 14px;
}
.form-horizontal .control-label {
    display: inline-block;
    width: 20%;
    text-align: right;
}
.form-horizontal fieldset {
    margin-bottom: 30px;
}
.form-horizontal .control-group {
    display: flex;
    align-items: center;
}
.form-horizontal .controls {
    padding-left: 15px;
}
textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
    background-color: #fff;
    border: 1px solid rgba(68, 68, 68, 0.1);
    color: #333;
    padding: 5px 15px;
    font-size: 13px;
    transition: border-color .3s ease;
    height: 40px;
}
textarea {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100px;
}
textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, textarea:focus {
    border-color: rgba(68, 68, 68, 0.5);
    transition: border-color .3s ease;
}
.inputbox.rsform-error, .textbox.rsform-error {
    border-color: rgba(238, 68, 68, 0.5);
}
input::-webkit-input-placeholder {
    color: #666
}
input::-moz-placeholder {
    color: #666
}
textarea::-webkit-input-placeholder {
    color: #666
}
textarea::-moz-placeholder {
    color: #666
}

/* Responsive Video */

.video {
    position: relative;
    height: 0;
    overflow: hidden;
    padding: 0 0 57% 0;
}
.video iframe {
    border: none;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%
}
.video-max-720 {
    max-width: 720px;
    margin: 0 auto 50px;
    padding: 0;
}
.video-max-720 iframe {
    position: static;
    width: 100%;
    max-height: 400px;
}

/* ========= Buttons =======
======================================= */

.btn-block {
    text-align: center;
    margin: 30px 0 0
}
.btn-block.btn-block-top {
    margin: 0 0 30px;
}
.btn {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    cursor: pointer;
    color: #333;
    display: inline-block;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    text-decoration: none;
    transition: all .2s ease;
    vertical-align: top;
    height: 30px;
}
.btn.btn-s35 {
    line-height: 33px;
    height: 35px;
}
.btn.btn-s40 {
    line-height: 38px;
    height: 40px;
}
.btn.btn-s45 {
    line-height: 43px;
    height: 45px;
}
.btn.btn-s50 {
    line-height: 48px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 14px;
    height: 50px;
}
.btn.btn-round {
    border-radius: calc(20px + 10px);
}

/* Colors */

.btn.btn-blue {
    background-color: #3498c6;
    border-color: #3498c6;
    color: #fff;
}
.btn.btn-white {
    border-color: #fff;
    background-color: #fff;
    color: #333
}
.btn.btn-grey {
    background-color: #ccc;
    border-color: #ccc;
    color: #666
}
.btn-brown {
    border-color: #c83b18;
    background: -webkit-linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(253, 117, 57, 0.6) 0%, rgba(253, 117, 57, 0) 100%);
    background: -moz-linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(253, 117, 57, 0.6) 0%, rgba(253, 117, 57, 0) 100%);
    background: -o-linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(253, 117, 57, 0.6) 0%, rgba(253, 117, 57, 0) 100%);
    background: -ms-linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(253, 117, 57, 0.6) 0%, rgba(253, 117, 57, 0) 100%);
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.6) 0%, rgba(253, 117, 57, 0.6) 0%, rgba(253, 117, 57, 0) 100%);
    background-color: #c83b18;
    color: #fff;
}

/* Btn Empty */

.btn.btn-empty {
    background: transparent;
    border-color: #29b6f6;
    color: #29b6f6;
}
.btn.btn-empty.btn-grey {
    border-color: #ccc;
    color: #ccc;
}
.btn.btn-empty.btn-white {
    border-color: #fff;
    color: #fff;
}
.btn.btn-empty.btn-white:hover {
    background-color: #555;
    border-color: #555;
}

/* Btn Width 100% */

.btn.btn-w100 {
    padding: 0 15px;
    width: 100%;
}

/* Hover Btn */

.btn:hover {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    transition: all .2s ease;
}
.btn.btn-empty:hover {
    background: #29b6f6;
    border-color: #29b6f6;
    color: #fff;
}
.btn.btn-blue:hover {
    background-color: #555;
    border-color: #555;
    color: #fff
}
.btn.btn-grey:hover {
    color: #fff
}
.btn.btn-brown:hover {
    background: #666;
    border-color: #666;
}

/* ====== Table ===== */

table {
    border-collapse: collapse;
    border-spacing: 0;
}
td, th {
    padding: 0;
}
.table {
    color: #444;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    min-width: 400px;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #dddddd;
}
.table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #dddddd;
}
.table-responsive {
    overflow-x: auto;
    min-height: 0.01%;
}
.table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {
    border-top: 0;
}
.table > tbody + tbody {
    border-top: 2px solid #dddddd;
}
.table .table {
    background-color: #ffffff;
}
.table-condensed > thead > tr > th, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > td {
    padding: 5px;
}
.table-bordered {
    border: 1px solid #dddddd;
}
.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
    border: 1px solid #dddddd;
}
.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
    border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}
.table-hover > tbody > tr:hover {
    background-color: #f5f5f5;
}

/** ===== System Messages ===== **/

.alert {
    padding: 8px 35px 8px 14px;
    margin-bottom: 20px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    background-color: #fcf8e3;
    border: 1px solid #fbeed5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.alert, .alert h4 {
    color: #c09853;
}
.alert h4 {
    margin: 0;
}
.alert .close {
    position: relative;
    top: -2px;
    right: -21px;
    line-height: 20px;
}
.alert-success {
    color: #468847;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.alert-success h4 {
    color: #468847;
}
.alert-danger, .alert-error {
    color: #b94a48;
    background-color: #f2dede;
    border-color: #eed3d7;
}
.alert-danger h4, .alert-error h4 {
    color: #b94a48;
}
.alert-info {
    color: #3a87ad;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
.alert-info h4 {
    color: #3a87ad;
}
.alert-block {
    padding-top: 14px;
    padding-bottom: 14px;
}
.alert-block > p, .alert-block > ul {
    margin-bottom: 0;
}
.alert-block p + p {
    margin-top: 5px;
}
.close {
    float: right;
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    color: #000000;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.2;
    filter: alpha(opacity=20);
}
.close:hover, .close:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
    filter: alpha(opacity=40);
}
button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}

/** ====== Tootltip ======
==================================== **/

.easy-tooltip {
    background: #555555;
    font-size: 12px;
    color: #fff;
    padding: 5px 10px;
    position: relative;
}
.easy-tooltip::before {
    border-bottom: 5px solid rgba(0, 0, 0, 0.7);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    position: absolute;
    top: -5px;
    left: 5px;
    height: 0;
    width: 0
}

/** ======= Accordeon ====== **/

.accordion {
    margin-bottom: 20px;
}
.accordion-group {
    margin-bottom: 2px;
    border: 1px solid #e5e5e5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.accordion-heading {
    border-bottom: 0;
}
.accordion-heading .accordion-toggle {
    display: block;
    padding: 8px 15px;
}
.accordion-toggle {
    cursor: pointer;
}
.accordion-inner {
    padding: 9px 15px;
    border-top: 1px solid #e5e5e5;
}

/** ======= Collapse ====== **/

.collapse {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    -moz-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
}
.collapse.in {
    height: auto;
}

/* ===== TABS ===== */


/* ===== TABS ===== */

.tabs {
    margin-top: 30px
}
.nav-tabs {
    border-top: 2px solid #e8e8e8;
    border-bottom: 2px solid #e8e8e8;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px;
    width: 100%
}
ul.nav-tabs > li {
    float: left;
}
ul.nav-tabs > li:not(:last-child) {
    margin: 0 2px 0 0
}
.nav-tabs > li > a {
    background: #e8e8e8;
    border-radius: 5px 5px 0 0;
    color: #608c51;
    display: block;
    padding: 15px 40px;
    text-decoration: none;
}
.nav-tabs > li > a:hover {
    background: #cfcfcf
}
.nav-tabs > li.active > a {
    background: #608c51;
    color: #fff
}
.tabs-content {
    margin-top: 15px;
}
.tabs-content .tab-block {
    display: none;
}
.tabs-content .tab-block.active {
    display: block;
}

/** GO TOP LINK **/

.go_top_link {
    background-color: $blueCol;
    bottom: 30px;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 20px;
    height: 50px;
    line-height: 50px;
    position: fixed;
    right: 30px;
    text-align: center;
    text-decoration: none;
    transition: opacity .3s ease;
    opacity: 0.5;
    width: 50px;
    z-index: 20
}
.go_top_link:hover {
    transition: opacity .3s ease;
    opacity: 1
}
.go_top_link .icon-next {
    margin: 0;
    transform: rotate(-90deg);
    display: inline-block;
}

/* ============ Audio player ======= */

.audioplayer {
    height: 45px;
    color: #999999;
    position: relative;
    z-index: 1;
    background-image: -moz-linear-gradient( 135deg, rgba(253, 117, 57, 0.6) 0%, rgba(0, 0, 0, 0.6) 0%, rgb(0, 0, 0) 100%);
    background-image: -webkit-linear-gradient( 135deg, rgba(253, 117, 57, 0.6) 0%, rgba(0, 0, 0, 0.6) 0%, rgb(0, 0, 0) 100%);
    background-image: -ms-linear-gradient( 135deg, rgba(253, 117, 57, 0.6) 0%, rgba(0, 0, 0, 0.6) 0%, rgb(0, 0, 0) 100%);
    border-radius: 20px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.audioplayer-mini {
    width: 2.5em;
    /* 40 */
    margin: 0 auto;
}
.audioplayer > div {
    position: absolute;
}
.audioplayer-playpause {
    height: 100%;
    text-align: left;
    text-indent: -9999px;
    cursor: pointer;
    z-index: 2;
    top: 0;
    left: 20px;
    width: 15px;
}
.audioplayer-mini .audioplayer-playpause {
    width: 100%;
}
.audioplayer-playpause a {
    display: block;
}
.audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
    width: 0;
    height: 0;
    border: 0.5em solid transparent;
    border-right: none;
    border-left-color: $yellowCol;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.5em 0 0 -0.25em;
}
.audioplayer-playing .audioplayer-playpause a {
    width: 12px;
    height: 15px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.audioplayer-playing .audioplayer-playpause a:before, .audioplayer-playing .audioplayer-playpause a:after {
    width: 4px;
    height: 100%;
    background-color: $orangeCol;
    content: '';
    position: absolute;
    top: 0;
}
.audioplayer-playing .audioplayer-playpause a:hover:before, .audioplayer-playing .audioplayer-playpause a:hover:after {
    background-color: #444;
}
.audioplayer-playing .audioplayer-playpause a:before {
    left: 0;
}
.audioplayer-playing .audioplayer-playpause a:after {
    right: 0;
}
.audioplayer-playpause a:hover, .audioplayer-playpause:focus a {
    border-left-color: #444 !important;
}
.audioplayer-time {
    width: 4.375em;
    /* 70 */
    height: 100%;
    line-height: 45px;
    /* 38 */
    text-align: center;
    z-index: 2;
    top: 0;
}
.audioplayer-time-current {
    left: 2.5em;
    /* 40 */
}
.audioplayer-time-duration {
    right: 2.5em;
    /* 40 */
}
.audioplayer-novolume .audioplayer-time-duration {
    border-right: 0;
    right: 0;
}
.audioplayer-bar {
    height: 11px;
    background-color: rgb(244, 244, 244);
    box-shadow: inset -0.515px 0.857px 2px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 1;
    top: 50%;
    right: 6.875em;
    left: 6.875em;
    transform: translateY(-50%);
}
.audioplayer-novolume .audioplayer-bar {
    right: 4.375em;
    /* 70 */
}
.audioplayer-bar div {
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.audioplayer-bar-loaded {
    background: #f7a302;
    background: -moz-linear-gradient(left, #f7a302 0%, #f28001 100%);
    background: -webkit-linear-gradient(left, #f7a302 0%, #f28001 100%);
    background: linear-gradient(to right, #f7a302 0%, #f28001 100%);
    z-index: 1;
}
.audioplayer-bar-played {
    background: $blueCol;
    z-index: 2;
}
.audioplayer-volume {
    height: 100%;
    text-align: left;
    text-indent: -9999px;
    cursor: pointer;
    z-index: 2;
    top: 0;
    right: 20px;
    width: 15px;
}
.audioplayer-volume:hover .audioplayer-volume-button a, .audioplayer-volume:focus .audioplayer-volume-button a {
    background-color: #000
}
.audioplayer-volume:hover .audioplayer-volume-button a::before, .audioplayer-volume:focus .audioplayer-volume-button a::before {
    border-right-color: #000;
}
.audioplayer-volume:hover .audioplayer-volume-button a::after, .audioplayer-volume:focus .audioplayer-volume-button a::after {
    border-color: #000 !important;
}
.audioplayer-volume-button {
    width: 100%;
    height: 100%;
}
.audioplayer-volume-button a {
    width: 0.313em;
    height: 0.375em;
    background-color: #777;
    display: block;
    position: relative;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    transition: none;
    left: 0;
}
.audioplayer-volume-button a:before, .audioplayer-volume-button a:after {
    content: '';
    position: absolute;
}
.audioplayer-volume-button a:before {
    width: 0;
    height: 0;
    border: 0.5em solid transparent;
    /* 8 */
    border-left: none;
    border-right-color: #777;
    z-index: 2;
    top: 50%;
    right: -0.25em;
    margin-top: -0.5em;
    /* 8 */
}
.audioplayer:not(.audioplayer-mute) .audioplayer-volume-button a:after {
    width: .313em;
    height: .313em;
    border: .25em double #777;
    border-width: .25em .25em 0 0;
    left: 10px;
    top: 0;
    border-radius: 0 .938em 0 0;
    transform: rotate(45deg);
}
.audioplayer-volume-adjust {
    height: 6.25em;
    cursor: default;
    position: absolute;
    left: 0;
    right: 1px;
    top: -9999px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}
.audioplayer-volume:not(:hover) .audioplayer-volume-adjust {
    opacity: 0;
}
.audioplayer-volume:hover .audioplayer-volume-adjust {
    top: auto;
    bottom: 100%;
}
.audioplayer-volume-adjust > div {
    width: 40%;
    height: 80%;
    background-color: #444;
    cursor: pointer;
    position: relative;
    z-index: 1;
    margin: 30% auto 0;
}
.audioplayer-volume-adjust div div {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $blueCol;
}
.audioplayer-novolume .audioplayer-volume {
    display: none;
}
.audioplayer-play, .audioplayer-pause, .audioplayer-volume a {}
.audioplayer-bar, .audioplayer-bar div, .audioplayer-volume-adjust div {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.audioplayer-bar, .audioplayer-volume-adjust > div {}
.audioplayer-volume-adjust div div, .audioplayer-bar-played {
    -webkit-box-shadow: inset 0 0 5px rgba( 255, 255, 255, .5);
    -moz-box-shadow: inset 0 0 5px rgba( 255, 255, 255, .5);
    box-shadow: inset 0 0 5px rgba( 255, 255, 255, .5);
}
.audioplayer-volume-adjust {
    box-shadow: -1px -1px 1px rgba(0, 0, 0, .15), 2px -2px 2px rgba(0, 0, 0, .15);
    background: #fff;
}
.audioplayer *, .audioplayer *:before, .audioplayer *:after {
    -webkit-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    -moz-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    -ms-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    -o-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
    transition: color .25s ease, background-color .25s ease, opacity .5s ease;
}
