/* ======== Footer ======
==================================== */

#footer {
    margin-top: 30px;
    min-width: 320px;
}
.footer-top {
    background-color: $whiteCol;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    padding-top: 25px;
    padding-bottom: 25px;
}
.logo-footer {
    display: inline-block;
    text-decoration: none;
}
.footer-top__contact {
    overflow: hidden;
}
.footer-top__contact p {
    color: #666;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.55;
    margin: 0;
}
.footer-top__contact p span {
    font-weight: 500
}
