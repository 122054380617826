.header__menu .menu {
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}
.header__menu .menu li a {
    background-color: #666;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    color: #fff;
    display: block;
    text-decoration: none;
    line-height: 40px;
    height: 40px;
}
.header__menu .menu li a.min-lev {
    background: #2bab61;
    background: -moz-linear-gradient(left, #2bab61 0%, #15893d 100%);
    background: -webkit-linear-gradient(left, #2bab61 0%, #15893d 100%);
    background: linear-gradient(to right, #2bab61 0%, #15893d 100%);
}
.header__menu .menu li a.min-middle-lev {
    background: #f7a302;
    background: -moz-linear-gradient(left, #f7a302 0%, #f28001 100%);
    background: -webkit-linear-gradient(left, #f7a302 0%, #f28001 100%);
    background: linear-gradient(to right, #f7a302 0%, #f28001 100%);
}
.header__menu .menu li a.middle-lev {
    background: #32a4d9;
    background: -moz-linear-gradient(left, #32a4d9 0%, #1a81c6 100%);
    background: -webkit-linear-gradient(left, #32a4d9 0%, #1a81c6 100%);
    background: linear-gradient(to right, #32a4d9 0%, #1a81c6 100%);
}
.header__menu .menu li a.hight-lev {
    background: #9055c9;
    background: -moz-linear-gradient(left, #9055c9 0%, #6a33af 100%);
    background: -webkit-linear-gradient(left, #9055c9 0%, #6a33af 100%);
    background: linear-gradient(to right, #9055c9 0%, #6a33af 100%);
}
.header__menu .menu li a:hover {
    background: $brownCol;
}
