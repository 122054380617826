/* Generated by Font Squirrel (https://www.fontsquirrel.com) on March 17, 2017 */

@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticablack-webfont.eot');
    src: url('../fonts/helveticablack-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticablack-webfont.woff2') format('woff2'), url('../fonts/helveticablack-webfont.woff') format('woff'), url('../fonts/helveticablack-webfont.svg#helveticaneuecyrblack') format('svg');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticablackitalic-webfont.eot');
    src: url('../fonts/helveticablackitalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticablackitalic-webfont.woff2') format('woff2'), url('../fonts/helveticablackitalic-webfont.woff') format('woff'), url('../fonts/helveticablackitalic-webfont.svg#helveticaneuecyrblack_italic') format('svg');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticabold-webfont.eot');
    src: url('../fonts/helveticabold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticabold-webfont.woff2') format('woff2'), url('../fonts/helveticabold-webfont.woff') format('woff'), url('../fonts/helveticabold-webfont.svg#helveticaneuecyrbold') format('svg');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticabolditalic-webfont.eot');
    src: url('../fonts/helveticabolditalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticabolditalic-webfont.woff2') format('woff2'), url('../fonts/helveticabolditalic-webfont.woff') format('woff'), url('../fonts/helveticabolditalic-webfont.svg#helveticaneuecyrbold_italic') format('svg');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticaheavy-webfont.eot');
    src: url('../fonts/helveticaheavy-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticaheavy-webfont.woff2') format('woff2'), url('../fonts/helveticaheavy-webfont.woff') format('woff'), url('../fonts/helveticaheavy-webfont.svg#helveticaneuecyrheavy') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticaheavyitalic-webfont.eot');
    src: url('../fonts/helveticaheavyitalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticaheavyitalic-webfont.woff2') format('woff2'), url('../fonts/helveticaheavyitalic-webfont.woff') format('woff'), url('../fonts/helveticaheavyitalic-webfont.svg#helveticaneuecyrheavy_italic') format('svg');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticaitalic-webfont.eot');
    src: url('../fonts/helveticaitalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticaitalic-webfont.woff2') format('woff2'), url('../fonts/helveticaitalic-webfont.woff') format('woff'), url('../fonts/helveticaitalic-webfont.svg#helveticaneuecyritalic') format('svg');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticalight-webfont.eot');
    src: url('../fonts/helveticalight-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticalight-webfont.woff2') format('woff2'), url('../fonts/helveticalight-webfont.woff') format('woff'), url('../fonts/helveticalight-webfont.svg#helveticaneuecyrlight') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticalightitalic-webfont.eot');
    src: url('../fonts/helveticalightitalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticalightitalic-webfont.woff2') format('woff2'), url('../fonts/helveticalightitalic-webfont.woff') format('woff'), url('../fonts/helveticalightitalic-webfont.svg#helveticaneuecyrlight_italic') format('svg');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticamedium-webfont.eot');
    src: url('../fonts/helveticamedium-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticamedium-webfont.woff2') format('woff2'), url('../fonts/helveticamedium-webfont.woff') format('woff'), url('../fonts/helveticamedium-webfont.svg#helveticaneuecyrmedium') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticamediumitaicl-webfont.eot');
    src: url('../fonts/helveticamediumitaicl-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticamediumitaicl-webfont.woff2') format('woff2'), url('../fonts/helveticamediumitaicl-webfont.woff') format('woff'), url('../fonts/helveticamediumitaicl-webfont.svg#helveticaneuecyrmedium_italic') format('svg');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticaregular-webfont.eot');
    src: url('../fonts/helveticaregular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticaregular-webfont.woff2') format('woff2'), url('../fonts/helveticaregular-webfont.woff') format('woff'), url('../fonts/helveticaregular-webfont.svg#helveticaneuecyrroman') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticathin-webfont.eot');
    src: url('../fonts/helveticathin-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticathin-webfont.woff2') format('woff2'), url('../fonts/helveticathin-webfont.woff') format('woff'), url('../fonts/helveticathin-webfont.svg#helveticaneuecyrthin') format('svg');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticathinitalic-webfont.eot');
    src: url('../fonts/helveticathinitalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticathinitalic-webfont.woff2') format('woff2'), url('../fonts/helveticathinitalic-webfont.woff') format('woff'), url('../fonts/helveticathinitalic-webfont.svg#helveticaneuecyrthin_italic') format('svg');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticaultralight-webfont.eot');
    src: url('../fonts/helveticaultralight-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticaultralight-webfont.woff2') format('woff2'), url('../fonts/helveticaultralight-webfont.woff') format('woff'), url('../fonts/helveticaultralight-webfont.svg#helveticaneuecyrultralight') format('svg');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helveticaultralightitalic-webfont.eot');
    src: url('../fonts/helveticaultralightitalic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/helveticaultralightitalic-webfont.woff2') format('woff2'), url('../fonts/helveticaultralightitalic-webfont.woff') format('woff'), url('../fonts/helveticaultralightitalic-webfont.svg#helveticaneuecyrultraLtIt') format('svg');
    font-weight: 200;
    font-style: italic;
}
