@charset "UTF-8";

/** Max-width 991px **/

@media (max-width: 991px) {
    .social > a {
        margin: 0 8px;
    }
    .item {
        padding: 15px;
    }
    .header__menu {
        margin-top: 15px;
    }
    .header__menu .menu {
        flex-wrap: wrap;
    }
    .header__menu .menu li {
        margin-bottom: 15px;
        width: 45%
    }
    .item__image {
        margin: -15px -15px 25px;
    }
}
@media (max-width: 768px) {
    .search-mobile {
        color: $brownCol;
        font-size: 14px;
        font-weight: 600;
        text-align: right;
        text-transform: uppercase;
        padding: 23px 0;
        height: 65px;
    }
    .search-mobile .icon-search {
        font-size: 15px;
        margin-right: 4px;
    }
    .search-mobile a {
        color: $brownCol;
        text-decoration: none;
    }
    .header__mod-search {
        background: #fff;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
        position: absolute;
        left: 15px;
        right: 15px;
        top: -100%;
        opacity: 0;
        transition: all .3s ease;
        padding: 10px;
        z-index: 10;
    }
    .header__mod-search.visible {
        top: 30px;
        opacity: 1;
        transition: all .3s ease;
    }
    .header__mod-search .icon-close {
        cursor: pointer;
        color: #444;
        font-size: 24px;
        position: absolute;
        right: 0;
        top: -24px;
        background: #fff;
    }
    .social.footer__social {
        text-align: center;
    }
    .full__body {
        padding: 15px;
    }
    .footer__counter {
        text-align: center;
        margin-top: 10px;
    }
    .footer__copyright {
        text-align: center;
    }
}
@media (max-width: 575px) {
    .header {
        margin-bottom: 20px;
    }
    .header__menu .menu li {
        width: 100%
    }
    .header__bottom {
        background-repeat: repeat;
    }
    .item__slovar {
        margin-right: 5px;
    }
    a.item__readmore .icon-right-arrow {
        margin-left: 4px;
    }
    .item__tags > a {
        margin-bottom: 5px;
    }
    .item__tags > a:not(:last-child) {
        margin-right: 5px;
    }
    .full__audiopl {
        margin-bottom: 20px;
    }
    .full__hits {
        margin-bottom: 15px;
    }
    .pagination-list > li.pagen-prev a, .pagination-list > li.pagen-first a, .pagination-list > li.pagen-next a, .pagination-list > li.pagen-end a {
        justify-content: center;
    }
}
@media (max-width: 360px) {
    .pagination.items-nav .pagination-list > li:first-child {
        margin: 0 0 15px;
    }
    .pagination.items-nav .pagination-list > li:last-child {
        margin: 0
    }
    .pagination.items-nav .pagination-list > li a {
        justify-content: center
    }
}

/** ====== Min-Width =======
=================================== **/

@media (min-width: 361px) {}
@media (min-width: 576px) {
    .header {
        margin-bottom: 70px;
    }
    .header__bottom {
        background-repeat: repeat-x;
    }
    .header__bottom > .container {
        height: 185px;
    }
    .item__slovar {
        margin-right: 30px;
    }
    a.item__readmore .icon-right-arrow {
        margin-left: 8px;
    }
    .item__tags > a:not(:last-child) {
        margin-right: 10px;
    }
    .full__footer {
        display: flex;
    }
    .footer__copyright p {
        display: inline-block;
        text-align: left;
    }
    .pagination-list > li.pagen-next {
        margin-left: 15px;
    }
    .pagination-list > li.pagen-prev {
        margin-right: 15px;
    }
}
@media (min-width: 769px) {
    .header__mod-search {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .full__body {
        padding: 30px 40px;
    }
    .footer__counter {
        text-align: right;
    }
}
@media (min-width: 990px) {
    .header {
        height: 250px;
    }
    .social > a {
        margin: 0 12px;
    }
    .item {
        padding: 25px 30px;
    }
    .header__menu {
        margin-top: 40px;
    }
    .header__menu .menu li {
        width: 25%
    }
    .header__menu .menu li:not(:last-child) {
        margin-right: 30px;
    }
}

/** Min-width 992px **/

@media (min-width: 992px) {
    #page {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        min-height: 100vh;
    }
    .contentleft, .contentmiddle {
        position: relative;
    }
    .contentleft {
        left: 33.3333%;
    }
    .contentmiddle {
        left: 25%;
    }
    .left.sidebar {
        position: relative;
        left: -66.6667%;
    }
    .left.sidebar.bothSidebars {
        left: -50%
    }
    .item__image {
        margin: -25px -30px 25px;
    }
}

/** Hidden elements **/

.invisible {
    visibility: hidden !important;
}
.hidden-xs-up {
    display: none !important;
}
@media (max-width: 575px) {
    .hidden-xs-down {
        display: none !important;
    }
}
@media (min-width: 576px) {
    .hidden-sm-up {
        display: none !important;
    }
}
@media (max-width: 767px) {
    .hidden-sm-down {
        display: none !important;
    }
}
@media (min-width: 768px) {
    .hidden-md-up {
        display: none !important;
    }
}
@media (max-width: 991px) {
    .hidden-md-down {
        display: none !important;
    }
}
@media (min-width: 992px) {
    .hidden-lg-up {
        display: none !important;
    }
}
@media (max-width: 1199px) {
    .hidden-lg-down {
        display: none !important;
    }
}
@media (min-width: 1200px) {
    .hidden-xl-up {
        display: none !important;
    }
}
.hidden-xl-down {
    display: none !important;
}
