/** ============================

 * ZM-IT - разработка сайтов
 * http://zm-it.ru
 * E-mail: info@zm-it.ru
 * Skype: zaur-mag11
 * Phone: +79064495544

===================================== **/

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    min-height: 100%;
    position: relative
}
*, *::after, *::before {
    box-sizing: inherit;
}
body {
    background-color: #f9f9f9;
    font-family: 'Helvetica', sans-serif;
    margin: 0;
    padding: 0;
}

/* ======== Page ======
=================================== */

#page {
    margin: 0 auto;
    text-align: left;
    min-width: 320px;
    max-width: 1920px;
}

/** Header **/

.header__top {
    background: #f9f9f9;
    box-shadow: 0px 0px 5px 0px #000000;
}
.header__logo {
    padding: 7px 0;
}
.header__bottom {
    background: url(../images/header.jpg) center top;
}
.header__bottom > .container {
    padding-top: 30px;
}
.header__site-slogan {
    color: #e5e5e5;
    /* font-size: 40px;*/
    font-size: calc(1.5rem + 6.4 * (100vw - 320px) / 640);
    font-weight: 300;
    text-align: center;
    max-width: 560px;
    margin: 0 auto;
}
.slogan-bold {
    color: #fefefe;
    font-weight: 600;
    text-shadow: 0px 0px 50px #000000;
}

/** Search **/

.header__search-form {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 15px;
    display: flex;
    padding: 1px;
    height: 35px;
}
input.search-input {
    background: transparent url(../images/search-icon.png) 12px 50% no-repeat;
    border: none;
    padding-left: 40px;
    height: 30px;
    width: 100%;
}
input.search-input::-webkit-input-placeholder {
    color: #666;
    font-style: italic;
}
input.search-input::-moz-placeholder {
    color: #666;
    font-style: italic;
}

/** Social icons **/

.social {
    font-size: 20px;
    padding: 20px 0;
    text-align: right;
}
.social > a {
    color: rgba(0, 0, 0, .4);
    text-decoration: none;
}
.social > a:hover {
    color: $brownCol;
}

/* ====== Main container =======
=========================================== */

.maincontainer {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

/* ======== Components ======
==================================== */


/* ======== Items teaser ====== */


/* Levels color */

.item__level {
    border-radius: 50%;
    min-width: 17px;
    margin-right: 12px;
    margin-top: 4px;
    height: 17px;
    width: 17px;
}
.min-lev {
    background: #2bab61;
    background: -moz-linear-gradient(left, #2bab61 0%, #15893d 100%);
    background: -webkit-linear-gradient(left, #2bab61 0%, #15893d 100%);
    background: linear-gradient(to right, #2bab61 0%, #15893d 100%);
}
.min-middle-lev {
    background: #f7a302;
    background: -moz-linear-gradient(left, #f7a302 0%, #f28001 100%);
    background: -webkit-linear-gradient(left, #f7a302 0%, #f28001 100%);
    background: linear-gradient(to right, #f7a302 0%, #f28001 100%);
}
.middle-lev {
    background: #32a4d9;
    background: -moz-linear-gradient(left, #32a4d9 0%, #1a81c6 100%);
    background: -webkit-linear-gradient(left, #32a4d9 0%, #1a81c6 100%);
    background: linear-gradient(to right, #32a4d9 0%, #1a81c6 100%);
}
.hight-lev {
    background: #9055c9;
    background: -moz-linear-gradient(left, #9055c9 0%, #6a33af 100%);
    background: -webkit-linear-gradient(left, #9055c9 0%, #6a33af 100%);
    background: linear-gradient(to right, #9055c9 0%, #6a33af 100%);
}

/*****/

.category__heading {
    position: relative;
    text-align: center;
    margin: 0 0 30px
}
.category__heading::before {
    border-top: 2px dotted #444;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0
}
.category__title {
    color: #444;
    margin: 0;
    font-size: 24px;
}
.category__title span {
    background: #f9f9f9;
    padding: 0 10px;
    position: relative;
    z-index: 1
}

/* Colors heading category */

.category__heading.category__min-lev::before {
    border-color: $greenCol;
}
.category__heading.category__min-middle-lev::before {
    border-color: $orangeCol;
}
.category__heading.category__min-lev .category__title {
    color: $greenCol;
}
.category__heading.category__min-middle-lev .category__title {
    color: $orangeCol;
}
.category__heading.category__middle-lev::before {
    border-color: $blueCol;
}
.category__heading.category__middle-lev .category__title {
    color: $blueCol;
}
.category__heading.category__hight-lev::before {
    border-color: $voletteCol;
}
.category__heading.category__hight-lev .category__title {
    color: $voletteCol;
}

/*** End color heading ***/

.teaser > .row > [class^="col-"] {
    margin-bottom: 30px;
}
.item {
    border-radius: 3px;
    background: $whiteCol;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: 100%
}
.item__image img {
    display: block;
    width: 100%;
}
.item__header {
    align-items: flex-start;
    display: flex;
    margin-bottom: 20px;
}
.item__title {
    font-size: 20px;
    font-weight: 500;
}
.item__title a {
    color: #444444;
    text-decoration: none;
}
.item__title a:hover {
    color: $brownCol;
    text-decoration: underline;
}
.item__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.item__tags {
    font-size: 12px;
    margin-bottom: 15px;
}
.item__tags > a {
    border: 1px solid rgba(0, 0, 0, .3);
    color: #666;
    display: inline-block;
    text-decoration: none;
    padding: 3px 8px;
    margin-bottom: 8px;
}
.item__tags > a:hover {
    background: #666;
    color: $whiteCol;
}
.item__anons {
    color: #444444;
    font-size: 14px;
    line-height: 20px;
}
.item__footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    padding: 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.item__hits {
    color: #666;
    font-size: 14px;
}
.item__hits .icon-eye {
    vertical-align: top;
    margin-right: 5px;
}
.icon-slovar {
    background: url(../images/slovar.png) no-repeat;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    width: 20px;
    height: 23px;
}
.item__more {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}
.item__slovar {
    color: $brownCol;
    text-decoration: none;
}
a.item__slovar span {
    border-bottom: 1px dotted $brownCol;
    display: inline-block;
    vertical-align: middle;
}
a.item__slovar:hover span {
    border-bottom-color: $whiteCol;
}
a.item__readmore {
    color: $blueCol;
    text-decoration: none;
}
.category__min-lev a.item__readmore {
    color: $greenCol;
}
.category__min-middle-lev a.item__readmore {
    color: $orangeCol;
}
.category__hight-lev a.item__readmore {
    color: $voletteCol;
}
a.item__readmore .icon-right-arrow {
    font-size: 14px;
}
.teaser a.item__readmore:hover {
    color: #444;
}

/* ======== Item full ====== */

.full__lev-name {
    color: #444;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    position: relative;
    margin-bottom: 20px;
}
.full__lev-name::before {
    border-top: 2px dotted #444;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 1
}
.full__lev-name span {
    background: #f9f9f9;
    display: inline-block;
    padding: 0 10px;
    position: relative;
    z-index: 2
}

/* Colors heading full */

.min-lev-name.full__lev-name::before {
    border-color: $greenCol;
}
.min-lev-name.full__lev-name {
    color: $greenCol;
}
.min-middle-lev-name.full__lev-name::before {
    border-color: $orangeCol;
}
.min-middle-lev-name.full__lev-name {
    color: $orangeCol;
}
.middle-lev-name.full__lev-name::before {
    border-color: $blueCol;
}
.middle-lev-name.full__lev-name {
    color: $blueCol;
}
.hight-lev-name.full__lev-name::before {
    border-color: $voletteCol;
}
.hight-lev-name.full__lev-name {
    color: $voletteCol;
}

/******/

.full__item {
    border-radius: 3px;
    background-color: $whiteCol;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
}
.full__image-video img {
    display: block;
}
.full__header {
    align-items: flex-start;
    display: flex;
    margin-bottom: 30px;
}
.full__header .item__level {
    margin-top: 6px;
}
.full__title {
    margin: 0
}
.full__audiopl audio {
    width: 100%;
    height: 35px;
}
.full_text {
    margin-top: 40px;
    word-wrap: break-word;
}
.full__slovar {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%
}
.full__slovar .icon-slovar {
    background: url(../images/slovar-white.png) center no-repeat;
    vertical-align: middle;
}
.full__slovar span {
    border-bottom: 1px dotted $whiteCol;
}
.full__tags {
    font-size: 14px;
    margin-bottom: 40px;
}
.full__footer {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 25px 0 0;
    justify-content: space-between;
}
.full__hits {
    color: #3e3e40;
    font-size: 14px;
    font-weight: 600
}
.full__hits .icon-eye {
    font-size: 16px;
    margin-right: 5px;
    vertical-align: top;
}
.full__share {
    display: flex;
    align-items: center;
}
.full__share-title {
    color: #3e3e40;
    font-size: 14px;
    margin-right: 8px;
}

/** ====== Search component ======== **/

.search_buttons_block {
    border-bottom: 2px solid $blueCol;
    margin: 0 0 30px;
    padding: 0 0 30px;
    display: flex;
    min-width: 260px;
}
.search_buttons_block .btn {
    padding: 0 20px
}
#searchForm #search-searchword {
    width: calc(11rem + 900 * (100vw - 320px) / 880);
    min-width: 176px;
}
.phrases {
    border-bottom: 1px solid #c8c8c8;
    border-top: 1px solid #c8c8c8;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    margin: 0 0 20px;
}
.phrases-box {
    margin-right: 15px;
}
.phrases-box label {
    color: #383838;
    margin-right: 15px;
    font-size: 14px;
}
.phrases-box .jq-radio {
    margin-right: 5px;
}
.phrases-box input[type="radio"] {
    margin-right: 5px
}
.ordering-box {
    font-size: 14px;
    color: #383838;
}
.form-limit {}
@media (max-width: 768px) {
    .phrases {
        flex-direction: column;
    }
    .phrases > div:not(:last-child) {
        margin-bottom: 15px;
    }
}
#searchForm .chzn-results {
    margin: 0 4px 4px 0;
    font-size: 14px;
}
#searchForm .chzn-results li {
    padding: 5px 10px;
}
#searchForm .ordering {
    margin-right: 5px;
    line-height: 24px;
}
.result_block {
    border-bottom: 1px dotted #999;
    margin: 0 0 20px;
    padding: 0 0 20px;
    font-size: 14px;
}
.result-title {
    font-size: 16px;
    margin-bottom: 5px;
}
.result-text {
    color: #383838;
}
.result-category {
    color: #999;
    margin-bottom: 5px;
}

/* ======== Pagination ====== */

.pagination {
    margin-top: 30px;
}
.pagination-list {
    color: #777;
    font-size: 0;
    list-style: none;
    text-align: center;
    font-size: 14px;
}
.pagination-list > li {
    vertical-align: top;
    line-height: 30px;
    display: inline-block;
    margin: 0 7.5px 7.5px;
}
.pagination-list > li a, .pagination-list > li > span, .pagination-list > li.disabled span {
    border-radius: 50%;
    background-color: #f2f2f2;
    background: -webkit-linear-gradient(135deg, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0) 100%);
    background: -moz-linear-gradient(135deg, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0) 100%);
    background: -o-linear-gradient(135deg, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0) 100%);
    background: -ms-linear-gradient(135deg, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(-45deg, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0) 100%);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    display: block;
    height: 30px;
}
.pagination-list > li.pagen-li a, .pagination-list > li.pagen-li > span, .pagination-list > li.active > span {
    width: 30px;
}
.pagination-list > li a {
    color: #777;
    text-decoration: none;
}
.pagination-list > li.active span, .pagination-list > li a:hover {
    background: #c83b18;
    background: -moz-linear-gradient(left, #c83b18 0%, #e85e2c 100%);
    background: -webkit-linear-gradient(left, #c83b18 0%, #e85e2c 100%);
    background: linear-gradient(to right, #c83b18 0%, #e85e2c 100%);
    color: #fff;
}
.pagination-list > li.disabled span {
    border-radius: 20px;
    padding: 0 20px;
}
.pagination-list > li.pagen-next a, .pagination-list > li.pagen-end a, .pagination-list > li.pagen-prev a, .pagination-list > li.pagen-first a {
    border-radius: 20px;
    background: #c83b18;
    background: -moz-linear-gradient(left, #c83b18 0%, #e85e2c 100%);
    background: -webkit-linear-gradient(left, #c83b18 0%, #e85e2c 100%);
    background: linear-gradient(to right, #c83b18 0%, #e85e2c 100%);
    color: #fff;
    padding: 0 20px;
}
.pagination-list > li.disabled span, .pagination-list > li.pagen-next a, .pagination-list > li.pagen-end a, .pagination-list > li.pagen-prev a, .pagination-list > li.pagen-first a {
    display: flex;
    align-items: center;
}
.pagination-list .icon-back {
    margin-right: 5px;
}
.pagination-list .icon-next {
    margin-left: 5px;
}

/* ======== Footer ======
==================================== */

#footer > .container {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
}
#footer > .container::before {
    background-color: rgba(0, 0, 0, 0.1);
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    height: 1px;
}
.footer__copyright p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
}
.footer__copyright p a {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    text-decoration: none;
}
.footer__copyright p a:hover {
    color: $brownCol;
    text-decoration: underline;
}
.footer__copyright-dvr {
    display: inline-block;
    margin: 0 25px;
}

/** Fix для браузера ИЕ11 **/

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    #page {
        display: block;
    }
}
