@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icons/icomoon.eot?k1sx9d');
    src: url('../fonts/icons/icomoon.eot?k1sx9d#iefix') format('embedded-opentype'), url('../fonts/icons/icomoon.ttf?k1sx9d') format('truetype'), url('../fonts/icons/icomoon.woff?k1sx9d') format('woff'), url('../fonts/icons/icomoon.svg?k1sx9d#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class^="icon-"], [class*=" icon-"] {
    display: inline-block;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-volume:before {
    content: "\e90b";
}
.icon-volume2:before {
    content: "\e90d";
}
.icon-sitemap:before {
    content: "\e90f";
}
.icon-sitemap2:before {
    content: "\e910";
}
.icon-eye:before {
    content: "\e907";
}
.icon-back-end:before {
    content: "\e911";
}
.icon-back:before {
    content: "\e90a";
}
.icon-next-end:before {
    content: "\e90e";
}
.icon-next:before {
    content: "\e906";
}
.icon-right-arrow:before {
    content: "\e905";
}
.icon-search:before {
    content: "\e901";
}
.icon-eye-outline:before {
    content: "\e908";
}
.icon-youtube:before {
    content: "\e909";
}
.icon-instagram:before {
    content: "\e900";
}
.icon-menu:before {
    content: "\e90c";
}
.icon-close:before {
    content: "\e902";
}
.icon-social-facebook:before {
    content: "\e903";
}
.icon-vk:before {
    content: "\e904";
}
.icon-youtube2:before {
    content: "\ea9e";
}
