@charset "UTF-8";
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

@media (min-width: 576px) {
  .container {
    width: 540px;
    max-width: 100%; } }

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%; } }

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%; } }

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px; }

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

@media (min-width: 576px) {
  .row {
    margin-right: -15px;
    margin-left: -15px; } }

@media (min-width: 768px) {
  .row {
    margin-right: -15px;
    margin-left: -15px; } }

@media (min-width: 992px) {
  .row {
    margin-right: -15px;
    margin-left: -15px; } }

@media (min-width: 1200px) {
  .row {
    margin-right: -15px;
    margin-left: -15px; } }

.col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
  position: relative;
  min-height: 1px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

@media (min-width: 576px) {
  .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 768px) {
  .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 992px) {
  .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 1200px) {
  .col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    padding-right: 15px;
    padding-left: 15px; } }

.col-xs {
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-xs-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.333333%;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-xs-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.666667%;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-xs-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-xs-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.333333%;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-xs-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 41.666667%;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-xs-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-xs-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58.333333%;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-xs-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.666667%;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-xs-9 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-xs-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 83.333333%;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-xs-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 91.666667%;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-xs-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.pull-xs-0 {
  right: auto; }

.pull-xs-1 {
  right: 8.333333%; }

.pull-xs-2 {
  right: 16.666667%; }

.pull-xs-3 {
  right: 25%; }

.pull-xs-4 {
  right: 33.333333%; }

.pull-xs-5 {
  right: 41.666667%; }

.pull-xs-6 {
  right: 50%; }

.pull-xs-7 {
  right: 58.333333%; }

.pull-xs-8 {
  right: 66.666667%; }

.pull-xs-9 {
  right: 75%; }

.pull-xs-10 {
  right: 83.333333%; }

.pull-xs-11 {
  right: 91.666667%; }

.pull-xs-12 {
  right: 100%; }

.push-xs-0 {
  left: auto; }

.push-xs-1 {
  left: 8.333333%; }

.push-xs-2 {
  left: 16.666667%; }

.push-xs-3 {
  left: 25%; }

.push-xs-4 {
  left: 33.333333%; }

.push-xs-5 {
  left: 41.666667%; }

.push-xs-6 {
  left: 50%; }

.push-xs-7 {
  left: 58.333333%; }

.push-xs-8 {
  left: 66.666667%; }

.push-xs-9 {
  left: 75%; }

.push-xs-10 {
  left: 83.333333%; }

.push-xs-11 {
  left: 91.666667%; }

.push-xs-12 {
  left: 100%; }

.offset-xs-1 {
  margin-left: 8.333333%; }

.offset-xs-2 {
  margin-left: 16.666667%; }

.offset-xs-3 {
  margin-left: 25%; }

.offset-xs-4 {
  margin-left: 33.333333%; }

.offset-xs-5 {
  margin-left: 41.666667%; }

.offset-xs-6 {
  margin-left: 50%; }

.offset-xs-7 {
  margin-left: 58.333333%; }

.offset-xs-8 {
  margin-left: 66.666667%; }

.offset-xs-9 {
  margin-left: 75%; }

.offset-xs-10 {
  margin-left: 83.333333%; }

.offset-xs-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-sm-0 {
    right: auto; }
  .pull-sm-1 {
    right: 8.333333%; }
  .pull-sm-2 {
    right: 16.666667%; }
  .pull-sm-3 {
    right: 25%; }
  .pull-sm-4 {
    right: 33.333333%; }
  .pull-sm-5 {
    right: 41.666667%; }
  .pull-sm-6 {
    right: 50%; }
  .pull-sm-7 {
    right: 58.333333%; }
  .pull-sm-8 {
    right: 66.666667%; }
  .pull-sm-9 {
    right: 75%; }
  .pull-sm-10 {
    right: 83.333333%; }
  .pull-sm-11 {
    right: 91.666667%; }
  .pull-sm-12 {
    right: 100%; }
  .push-sm-0 {
    left: auto; }
  .push-sm-1 {
    left: 8.333333%; }
  .push-sm-2 {
    left: 16.666667%; }
  .push-sm-3 {
    left: 25%; }
  .push-sm-4 {
    left: 33.333333%; }
  .push-sm-5 {
    left: 41.666667%; }
  .push-sm-6 {
    left: 50%; }
  .push-sm-7 {
    left: 58.333333%; }
  .push-sm-8 {
    left: 66.666667%; }
  .push-sm-9 {
    left: 75%; }
  .push-sm-10 {
    left: 83.333333%; }
  .push-sm-11 {
    left: 91.666667%; }
  .push-sm-12 {
    left: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-md-0 {
    right: auto; }
  .pull-md-1 {
    right: 8.333333%; }
  .pull-md-2 {
    right: 16.666667%; }
  .pull-md-3 {
    right: 25%; }
  .pull-md-4 {
    right: 33.333333%; }
  .pull-md-5 {
    right: 41.666667%; }
  .pull-md-6 {
    right: 50%; }
  .pull-md-7 {
    right: 58.333333%; }
  .pull-md-8 {
    right: 66.666667%; }
  .pull-md-9 {
    right: 75%; }
  .pull-md-10 {
    right: 83.333333%; }
  .pull-md-11 {
    right: 91.666667%; }
  .pull-md-12 {
    right: 100%; }
  .push-md-0 {
    left: auto; }
  .push-md-1 {
    left: 8.333333%; }
  .push-md-2 {
    left: 16.666667%; }
  .push-md-3 {
    left: 25%; }
  .push-md-4 {
    left: 33.333333%; }
  .push-md-5 {
    left: 41.666667%; }
  .push-md-6 {
    left: 50%; }
  .push-md-7 {
    left: 58.333333%; }
  .push-md-8 {
    left: 66.666667%; }
  .push-md-9 {
    left: 75%; }
  .push-md-10 {
    left: 83.333333%; }
  .push-md-11 {
    left: 91.666667%; }
  .push-md-12 {
    left: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-lg-0 {
    right: auto; }
  .pull-lg-1 {
    right: 8.333333%; }
  .pull-lg-2 {
    right: 16.666667%; }
  .pull-lg-3 {
    right: 25%; }
  .pull-lg-4 {
    right: 33.333333%; }
  .pull-lg-5 {
    right: 41.666667%; }
  .pull-lg-6 {
    right: 50%; }
  .pull-lg-7 {
    right: 58.333333%; }
  .pull-lg-8 {
    right: 66.666667%; }
  .pull-lg-9 {
    right: 75%; }
  .pull-lg-10 {
    right: 83.333333%; }
  .pull-lg-11 {
    right: 91.666667%; }
  .pull-lg-12 {
    right: 100%; }
  .push-lg-0 {
    left: auto; }
  .push-lg-1 {
    left: 8.333333%; }
  .push-lg-2 {
    left: 16.666667%; }
  .push-lg-3 {
    left: 25%; }
  .push-lg-4 {
    left: 33.333333%; }
  .push-lg-5 {
    left: 41.666667%; }
  .push-lg-6 {
    left: 50%; }
  .push-lg-7 {
    left: 58.333333%; }
  .push-lg-8 {
    left: 66.666667%; }
  .push-lg-9 {
    left: 75%; }
  .push-lg-10 {
    left: 83.333333%; }
  .push-lg-11 {
    left: 91.666667%; }
  .push-lg-12 {
    left: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-xl-0 {
    right: auto; }
  .pull-xl-1 {
    right: 8.333333%; }
  .pull-xl-2 {
    right: 16.666667%; }
  .pull-xl-3 {
    right: 25%; }
  .pull-xl-4 {
    right: 33.333333%; }
  .pull-xl-5 {
    right: 41.666667%; }
  .pull-xl-6 {
    right: 50%; }
  .pull-xl-7 {
    right: 58.333333%; }
  .pull-xl-8 {
    right: 66.666667%; }
  .pull-xl-9 {
    right: 75%; }
  .pull-xl-10 {
    right: 83.333333%; }
  .pull-xl-11 {
    right: 91.666667%; }
  .pull-xl-12 {
    right: 100%; }
  .push-xl-0 {
    left: auto; }
  .push-xl-1 {
    left: 8.333333%; }
  .push-xl-2 {
    left: 16.666667%; }
  .push-xl-3 {
    left: 25%; }
  .push-xl-4 {
    left: 33.333333%; }
  .push-xl-5 {
    left: 41.666667%; }
  .push-xl-6 {
    left: 50%; }
  .push-xl-7 {
    left: 58.333333%; }
  .push-xl-8 {
    left: 66.666667%; }
  .push-xl-9 {
    left: 75%; }
  .push-xl-10 {
    left: 83.333333%; }
  .push-xl-11 {
    left: 91.666667%; }
  .push-xl-12 {
    left: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icons/icomoon.eot?k1sx9d");
  src: url("../fonts/icons/icomoon.eot?k1sx9d#iefix") format("embedded-opentype"), url("../fonts/icons/icomoon.ttf?k1sx9d") format("truetype"), url("../fonts/icons/icomoon.woff?k1sx9d") format("woff"), url("../fonts/icons/icomoon.svg?k1sx9d#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-volume:before {
  content: "\e90b"; }

.icon-volume2:before {
  content: "\e90d"; }

.icon-sitemap:before {
  content: "\e90f"; }

.icon-sitemap2:before {
  content: "\e910"; }

.icon-eye:before {
  content: "\e907"; }

.icon-back-end:before {
  content: "\e911"; }

.icon-back:before {
  content: "\e90a"; }

.icon-next-end:before {
  content: "\e90e"; }

.icon-next:before {
  content: "\e906"; }

.icon-right-arrow:before {
  content: "\e905"; }

.icon-search:before {
  content: "\e901"; }

.icon-eye-outline:before {
  content: "\e908"; }

.icon-youtube:before {
  content: "\e909"; }

.icon-instagram:before {
  content: "\e900"; }

.icon-menu:before {
  content: "\e90c"; }

.icon-close:before {
  content: "\e902"; }

.icon-social-facebook:before {
  content: "\e903"; }

.icon-vk:before {
  content: "\e904"; }

.icon-youtube2:before {
  content: "\ea9e"; }

/* ========== MAGNIFIC POPAP ======
========================================= */
.modal-content {
  background: #fff;
  padding: 30px;
  position: relative;
  margin: 0 auto;
  max-width: 450px; }

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: black;
  opacity: 0.75;
  filter: alpha(opacity=75); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.white-block {
  background: #fff;
  margin: 0 auto;
  padding: 30px;
  position: relative;
  max-width: 500px; }

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.fa.close-popap-btn {
  color: #29b6f6;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  right: 12px;
  top: 10px;
  margin: 0; }

.fa.close-popap-btn:hover {
  color: #555; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
  filter: alpha(opacity=100); }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
  filter: alpha(opacity=100); }

.mfp-arrow:before, .mfp-arrow:after, .mfp-arrow .mfp-b, .mfp-arrow .mfp-a {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after, .mfp-arrow .mfp-a {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before, .mfp-arrow .mfp-b {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after, .mfp-arrow-left .mfp-a {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before, .mfp-arrow-left .mfp-b {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after, .mfp-arrow-right .mfp-a {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before, .mfp-arrow-right .mfp-b {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

.white-popup-block {
  background: #fff;
  padding: 30px;
  position: relative;
  max-width: 500px;
  margin: 0 auto; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: center;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  margin-top: 5px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -o-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    -moz-transform-origin: 0;
    -ms-transform-origin: 0;
    -o-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    -moz-transform-origin: 100%;
    -ms-transform-origin: 100%;
    -o-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 15px;
    padding-right: 15px; } }

.mfp-ie7 .mfp-img {
  padding: 0; }

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }

.mfp-ie7 .mfp-container {
  padding: 0; }

.mfp-ie7 .mfp-content {
  padding-top: 44px; }

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0; }

.mfp-preloader {
  background: url(../images/icons/preloader.gif) center no-repeat #0B0B0B;
  text-indent: -999px;
  overflow: hidden;
  margin: 0 auto;
  height: 80px;
  width: 80px; }

/**
 * Simple Scale transition,
 */
.mfp-scale.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3); }

.mfp-scale.mfp-bg.mfp-ready {
  opacity: 0.95;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.mfp-scale.mfp-bg.mfp-removing {
  opacity: 0;
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3); }

.mfp-scale.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3); }

.mfp-scale.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

.mfp-scale.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3); }

/**
 * Bottom Up transition,
 */
.mfp-bottom-up.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-bottom-up.mfp-bg.mfp-ready {
  opacity: 0.4;
  transition: all 0.3s ease-out; }

.mfp-bottom-up.mfp-bg.mfp-removing {
  transition: all 0.3s ease-out;
  opacity: 0; }

.mfp-bottom-up.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  -moz-transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  -o-transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  top: 100%; }

.mfp-bottom-up.mfp-wrap.mfp-ready .mfp-content {
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  opacity: 1;
  top: 0; }

.mfp-bottom-up.mfp-wrap.mfp-removing .mfp-content {
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  opacity: 0;
  top: 100%; }

/**
 * Top Down transition,
 */
.mfp-top-up.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-top-up.mfp-bg.mfp-ready {
  opacity: 0.4;
  transition: all 0.3s ease-out; }

.mfp-top-up.mfp-bg.mfp-removing {
  transition: all 0.3s ease-out;
  opacity: 0; }

.mfp-top-up.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  -moz-transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  -o-transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  bottom: 100%; }

.mfp-top-up.mfp-wrap.mfp-ready .mfp-content {
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  opacity: 1;
  bottom: 0; }

.mfp-top-up.mfp-wrap.mfp-removing .mfp-content {
  transition: all 0.5s cubic-bezier(0.25, 0.98, 0.26, 0.99);
  opacity: 0;
  bottom: 100%; }

/**
 * Fade-zoom animation for first dialog
 */
/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8); }

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0; }

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8; }

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0; }

/**
 * Fade-move animation for second dialog
 */
/* at start */
.my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-20px) perspective(600px) rotateX(10deg);
  transform: translateY(-20px) perspective(600px) rotateX(10deg); }

/* animate in */
.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
  -moz-transform: translateY(0) perspective(600px) rotateX(0);
  -ms-transform: translateY(0) perspective(600px) rotateX(0);
  -o-transform: translateY(0) perspective(600px) rotateX(0);
  transform: translateY(0) perspective(600px) rotateX(0); }

/* animate out */
.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  -webkit-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -moz-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -ms-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  -o-transform: translateY(-10px) perspective(600px) rotateX(10deg);
  transform: translateY(-10px) perspective(600px) rotateX(10deg); }

/* Dark overlay, start state */
.my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

/* animate in */
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8; }

/* animate out */
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0; }

/** Modal Form **/
.modal-content {
  position: relative; }

.modal-content h4 {
  margin-top: 10px;
  text-align: center; }

.modal-content .form-row {
  margin-bottom: 30px; }

.modal-content .inputbox {
  background-color: #f2f2f2;
  color: #666;
  height: 50px;
  width: 100%; }

.modal-content .textbox {
  background-color: #f2f2f2;
  color: #555;
  padding: 10px 15px;
  height: 150px;
  width: 100%; }

.modal-content .inputbox::-webkit-input-placeholder {
  color: #666; }

.modal-content .inputbox::-moz-placeholder {
  color: #666; }

.modal-content .textbox::-webkit-input-placeholder {
  color: #666; }

.modal-content .textbox::-moz-placeholder {
  color: #666; }

.modal-content .form-row:last-child {
  margin-bottom: 0; }

.modal-content .btn-block {
  margin-bottom: 10px; }

.modal-content .success-text {
  color: #333; }

.modal-content .rsform-submit-button::before {
  content: "\f0a4";
  display: inline-block;
  font: normal normal normal 20px/1 FontAwesome;
  margin-right: 7px; }

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.well {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  padding: 25px;
  margin-bottom: 20px; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-clip: padding-box; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: ""; }

.btn .caret {
  margin-top: 13px;
  margin-left: 5px; }

.btn-group {
  position: relative;
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
  white-space: nowrap; }

.element-invisible {
  display: none; }

/*********** Html5 tags ************/
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

fieldset, img {
  border: none; }

img {
  max-width: 100%;
  height: auto; }

figure {
  margin: 1em 40px; }

.star, .red {
  color: red; }

.color-blue {
  color: #29b6f6; }

address {
  font-style: normal; }

/** Form elements **/
legend {
  display: block;
  margin: 0 0 30px;
  font-size: 24px; }

input, button, textarea {
  outline: none; }

.control-group:not(:last-child) {
  margin-bottom: 15px; }

.control-label {
  color: #444444;
  font-size: 14px; }

.form-horizontal .control-label {
  display: inline-block;
  width: 20%;
  text-align: right; }

.form-horizontal fieldset {
  margin-bottom: 30px; }

.form-horizontal .control-group {
  display: flex;
  align-items: center; }

.form-horizontal .controls {
  padding-left: 15px; }

textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
  background-color: #fff;
  border: 1px solid rgba(68, 68, 68, 0.1);
  color: #333;
  padding: 5px 15px;
  font-size: 13px;
  transition: border-color .3s ease;
  height: 40px; }

textarea {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100px; }

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, textarea:focus {
  border-color: rgba(68, 68, 68, 0.5);
  transition: border-color .3s ease; }

.inputbox.rsform-error, .textbox.rsform-error {
  border-color: rgba(238, 68, 68, 0.5); }

input::-webkit-input-placeholder {
  color: #666; }

input::-moz-placeholder {
  color: #666; }

textarea::-webkit-input-placeholder {
  color: #666; }

textarea::-moz-placeholder {
  color: #666; }

/* Responsive Video */
.video {
  position: relative;
  height: 0;
  overflow: hidden;
  padding: 0 0 57% 0; }

.video iframe {
  border: none;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%; }

.video-max-720 {
  max-width: 720px;
  margin: 0 auto 50px;
  padding: 0; }

.video-max-720 iframe {
  position: static;
  width: 100%;
  max-height: 400px; }

/* ========= Buttons =======
======================================= */
.btn-block {
  text-align: center;
  margin: 30px 0 0; }

.btn-block.btn-block-top {
  margin: 0 0 30px; }

.btn {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  cursor: pointer;
  color: #333;
  display: inline-block;
  padding: 0 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  text-decoration: none;
  transition: all .2s ease;
  vertical-align: top;
  height: 30px; }

.btn.btn-s35 {
  line-height: 33px;
  height: 35px; }

.btn.btn-s40 {
  line-height: 38px;
  height: 40px; }

.btn.btn-s45 {
  line-height: 43px;
  height: 45px; }

.btn.btn-s50 {
  line-height: 48px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 14px;
  height: 50px; }

.btn.btn-round {
  border-radius: calc(20px + 10px); }

/* Colors */
.btn.btn-blue {
  background-color: #3498c6;
  border-color: #3498c6;
  color: #fff; }

.btn.btn-white {
  border-color: #fff;
  background-color: #fff;
  color: #333; }

.btn.btn-grey {
  background-color: #ccc;
  border-color: #ccc;
  color: #666; }

.btn-brown {
  border-color: #c83b18;
  background: -webkit-linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(253, 117, 57, 0.6) 0%, rgba(253, 117, 57, 0) 100%);
  background: -moz-linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(253, 117, 57, 0.6) 0%, rgba(253, 117, 57, 0) 100%);
  background: -o-linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(253, 117, 57, 0.6) 0%, rgba(253, 117, 57, 0) 100%);
  background: -ms-linear-gradient(135deg, rgba(0, 0, 0, 0.6) 0%, rgba(253, 117, 57, 0.6) 0%, rgba(253, 117, 57, 0) 100%);
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.6) 0%, rgba(253, 117, 57, 0.6) 0%, rgba(253, 117, 57, 0) 100%);
  background-color: #c83b18;
  color: #fff; }

/* Btn Empty */
.btn.btn-empty {
  background: transparent;
  border-color: #29b6f6;
  color: #29b6f6; }

.btn.btn-empty.btn-grey {
  border-color: #ccc;
  color: #ccc; }

.btn.btn-empty.btn-white {
  border-color: #fff;
  color: #fff; }

.btn.btn-empty.btn-white:hover {
  background-color: #555;
  border-color: #555; }

/* Btn Width 100% */
.btn.btn-w100 {
  padding: 0 15px;
  width: 100%; }

/* Hover Btn */
.btn:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  transition: all .2s ease; }

.btn.btn-empty:hover {
  background: #29b6f6;
  border-color: #29b6f6;
  color: #fff; }

.btn.btn-blue:hover {
  background-color: #555;
  border-color: #555;
  color: #fff; }

.btn.btn-grey:hover {
  color: #fff; }

.btn.btn-brown:hover {
  background: #666;
  border-color: #666; }

/* ====== Table ===== */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

.table {
  color: #444;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  min-width: 400px; }

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #dddddd; }

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #dddddd; }

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; }

.table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {
  border-top: 0; }

.table > tbody + tbody {
  border-top: 2px solid #dddddd; }

.table .table {
  background-color: #ffffff; }

.table-condensed > thead > tr > th, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > td {
  padding: 5px; }

.table-bordered {
  border: 1px solid #dddddd; }

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #dddddd; }

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  border-bottom-width: 2px; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9; }

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5; }

/** ===== System Messages ===== **/
.alert {
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.alert, .alert h4 {
  color: #c09853; }

.alert h4 {
  margin: 0; }

.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: 20px; }

.alert-success {
  color: #468847;
  background-color: #dff0d8;
  border-color: #d6e9c6; }

.alert-success h4 {
  color: #468847; }

.alert-danger, .alert-error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7; }

.alert-danger h4, .alert-error h4 {
  color: #b94a48; }

.alert-info {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1; }

.alert-info h4 {
  color: #3a87ad; }

.alert-block {
  padding-top: 14px;
  padding-bottom: 14px; }

.alert-block > p, .alert-block > ul {
  margin-bottom: 0; }

.alert-block p + p {
  margin-top: 5px; }

.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20); }

.close:hover, .close:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity=40); }

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

/** ====== Tootltip ======
==================================== **/
.easy-tooltip {
  background: #555555;
  font-size: 12px;
  color: #fff;
  padding: 5px 10px;
  position: relative; }

.easy-tooltip::before {
  border-bottom: 5px solid rgba(0, 0, 0, 0.7);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: "";
  position: absolute;
  top: -5px;
  left: 5px;
  height: 0;
  width: 0; }

/** ======= Accordeon ====== **/
.accordion {
  margin-bottom: 20px; }

.accordion-group {
  margin-bottom: 2px;
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.accordion-heading {
  border-bottom: 0; }

.accordion-heading .accordion-toggle {
  display: block;
  padding: 8px 15px; }

.accordion-toggle {
  cursor: pointer; }

.accordion-inner {
  padding: 9px 15px;
  border-top: 1px solid #e5e5e5; }

/** ======= Collapse ====== **/
.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -moz-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease; }

.collapse.in {
  height: auto; }

/* ===== TABS ===== */
/* ===== TABS ===== */
.tabs {
  margin-top: 30px; }

.nav-tabs {
  border-top: 2px solid #e8e8e8;
  border-bottom: 2px solid #e8e8e8;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
  width: 100%; }

ul.nav-tabs > li {
  float: left; }

ul.nav-tabs > li:not(:last-child) {
  margin: 0 2px 0 0; }

.nav-tabs > li > a {
  background: #e8e8e8;
  border-radius: 5px 5px 0 0;
  color: #608c51;
  display: block;
  padding: 15px 40px;
  text-decoration: none; }

.nav-tabs > li > a:hover {
  background: #cfcfcf; }

.nav-tabs > li.active > a {
  background: #608c51;
  color: #fff; }

.tabs-content {
  margin-top: 15px; }

.tabs-content .tab-block {
  display: none; }

.tabs-content .tab-block.active {
  display: block; }

/** GO TOP LINK **/
.go_top_link {
  background-color: #32a4d9;
  bottom: 30px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  right: 30px;
  text-align: center;
  text-decoration: none;
  transition: opacity .3s ease;
  opacity: 0.5;
  width: 50px;
  z-index: 20; }

.go_top_link:hover {
  transition: opacity .3s ease;
  opacity: 1; }

.go_top_link .icon-next {
  margin: 0;
  transform: rotate(-90deg);
  display: inline-block; }

/* ============ Audio player ======= */
.audioplayer {
  height: 45px;
  color: #999999;
  position: relative;
  z-index: 1;
  background-image: -moz-linear-gradient(135deg, rgba(253, 117, 57, 0.6) 0%, rgba(0, 0, 0, 0.6) 0%, black 100%);
  background-image: -webkit-linear-gradient(135deg, rgba(253, 117, 57, 0.6) 0%, rgba(0, 0, 0, 0.6) 0%, black 100%);
  background-image: -ms-linear-gradient(135deg, rgba(253, 117, 57, 0.6) 0%, rgba(0, 0, 0, 0.6) 0%, black 100%);
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2); }

.audioplayer-mini {
  width: 2.5em;
  /* 40 */
  margin: 0 auto; }

.audioplayer > div {
  position: absolute; }

.audioplayer-playpause {
  height: 100%;
  text-align: left;
  text-indent: -9999px;
  cursor: pointer;
  z-index: 2;
  top: 0;
  left: 20px;
  width: 15px; }

.audioplayer-mini .audioplayer-playpause {
  width: 100%; }

.audioplayer-playpause a {
  display: block; }

.audioplayer:not(.audioplayer-playing) .audioplayer-playpause a {
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  border-right: none;
  border-left-color: #f8ad04;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5em 0 0 -0.25em; }

.audioplayer-playing .audioplayer-playpause a {
  width: 12px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%); }

.audioplayer-playing .audioplayer-playpause a:before, .audioplayer-playing .audioplayer-playpause a:after {
  width: 4px;
  height: 100%;
  background-color: #f7a302;
  content: '';
  position: absolute;
  top: 0; }

.audioplayer-playing .audioplayer-playpause a:hover:before, .audioplayer-playing .audioplayer-playpause a:hover:after {
  background-color: #444; }

.audioplayer-playing .audioplayer-playpause a:before {
  left: 0; }

.audioplayer-playing .audioplayer-playpause a:after {
  right: 0; }

.audioplayer-playpause a:hover, .audioplayer-playpause:focus a {
  border-left-color: #444 !important; }

.audioplayer-time {
  width: 4.375em;
  /* 70 */
  height: 100%;
  line-height: 45px;
  /* 38 */
  text-align: center;
  z-index: 2;
  top: 0; }

.audioplayer-time-current {
  left: 2.5em;
  /* 40 */ }

.audioplayer-time-duration {
  right: 2.5em;
  /* 40 */ }

.audioplayer-novolume .audioplayer-time-duration {
  border-right: 0;
  right: 0; }

.audioplayer-bar {
  height: 11px;
  background-color: #f4f4f4;
  box-shadow: inset -0.515px 0.857px 2px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1;
  top: 50%;
  right: 6.875em;
  left: 6.875em;
  transform: translateY(-50%); }

.audioplayer-novolume .audioplayer-bar {
  right: 4.375em;
  /* 70 */ }

.audioplayer-bar div {
  width: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.audioplayer-bar-loaded {
  background: #f7a302;
  background: -moz-linear-gradient(left, #f7a302 0%, #f28001 100%);
  background: -webkit-linear-gradient(left, #f7a302 0%, #f28001 100%);
  background: linear-gradient(to right, #f7a302 0%, #f28001 100%);
  z-index: 1; }

.audioplayer-bar-played {
  background: #32a4d9;
  z-index: 2; }

.audioplayer-volume {
  height: 100%;
  text-align: left;
  text-indent: -9999px;
  cursor: pointer;
  z-index: 2;
  top: 0;
  right: 20px;
  width: 15px; }

.audioplayer-volume:hover .audioplayer-volume-button a, .audioplayer-volume:focus .audioplayer-volume-button a {
  background-color: #000; }

.audioplayer-volume:hover .audioplayer-volume-button a::before, .audioplayer-volume:focus .audioplayer-volume-button a::before {
  border-right-color: #000; }

.audioplayer-volume:hover .audioplayer-volume-button a::after, .audioplayer-volume:focus .audioplayer-volume-button a::after {
  border-color: #000 !important; }

.audioplayer-volume-button {
  width: 100%;
  height: 100%; }

.audioplayer-volume-button a {
  width: 0.313em;
  height: 0.375em;
  background-color: #777;
  display: block;
  position: relative;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  transition: none;
  left: 0; }

.audioplayer-volume-button a:before, .audioplayer-volume-button a:after {
  content: '';
  position: absolute; }

.audioplayer-volume-button a:before {
  width: 0;
  height: 0;
  border: 0.5em solid transparent;
  /* 8 */
  border-left: none;
  border-right-color: #777;
  z-index: 2;
  top: 50%;
  right: -0.25em;
  margin-top: -0.5em;
  /* 8 */ }

.audioplayer:not(.audioplayer-mute) .audioplayer-volume-button a:after {
  width: .313em;
  height: .313em;
  border: .25em double #777;
  border-width: .25em .25em 0 0;
  left: 10px;
  top: 0;
  border-radius: 0 .938em 0 0;
  transform: rotate(45deg); }

.audioplayer-volume-adjust {
  height: 6.25em;
  cursor: default;
  position: absolute;
  left: 0;
  right: 1px;
  top: -9999px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.audioplayer-volume:not(:hover) .audioplayer-volume-adjust {
  opacity: 0; }

.audioplayer-volume:hover .audioplayer-volume-adjust {
  top: auto;
  bottom: 100%; }

.audioplayer-volume-adjust > div {
  width: 40%;
  height: 80%;
  background-color: #444;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin: 30% auto 0; }

.audioplayer-volume-adjust div div {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #32a4d9; }

.audioplayer-novolume .audioplayer-volume {
  display: none; }

.audioplayer-bar, .audioplayer-bar div, .audioplayer-volume-adjust div {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.audioplayer-volume-adjust div div, .audioplayer-bar-played {
  -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5);
  -moz-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5);
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.5); }

.audioplayer-volume-adjust {
  box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.15), 2px -2px 2px rgba(0, 0, 0, 0.15);
  background: #fff; }

.audioplayer *, .audioplayer *:before, .audioplayer *:after {
  -webkit-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
  -moz-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
  -ms-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
  -o-transition: color .25s ease, background-color .25s ease, opacity .5s ease;
  transition: color .25s ease, background-color .25s ease, opacity .5s ease; }

/* Generated by Font Squirrel (https://www.fontsquirrel.com) on March 17, 2017 */
@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticablack-webfont.eot");
  src: url("../fonts/helveticablack-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticablack-webfont.woff2") format("woff2"), url("../fonts/helveticablack-webfont.woff") format("woff"), url("../fonts/helveticablack-webfont.svg#helveticaneuecyrblack") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticablackitalic-webfont.eot");
  src: url("../fonts/helveticablackitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticablackitalic-webfont.woff2") format("woff2"), url("../fonts/helveticablackitalic-webfont.woff") format("woff"), url("../fonts/helveticablackitalic-webfont.svg#helveticaneuecyrblack_italic") format("svg");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticabold-webfont.eot");
  src: url("../fonts/helveticabold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticabold-webfont.woff2") format("woff2"), url("../fonts/helveticabold-webfont.woff") format("woff"), url("../fonts/helveticabold-webfont.svg#helveticaneuecyrbold") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticabolditalic-webfont.eot");
  src: url("../fonts/helveticabolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticabolditalic-webfont.woff2") format("woff2"), url("../fonts/helveticabolditalic-webfont.woff") format("woff"), url("../fonts/helveticabolditalic-webfont.svg#helveticaneuecyrbold_italic") format("svg");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticaheavy-webfont.eot");
  src: url("../fonts/helveticaheavy-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaheavy-webfont.woff2") format("woff2"), url("../fonts/helveticaheavy-webfont.woff") format("woff"), url("../fonts/helveticaheavy-webfont.svg#helveticaneuecyrheavy") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticaheavyitalic-webfont.eot");
  src: url("../fonts/helveticaheavyitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaheavyitalic-webfont.woff2") format("woff2"), url("../fonts/helveticaheavyitalic-webfont.woff") format("woff"), url("../fonts/helveticaheavyitalic-webfont.svg#helveticaneuecyrheavy_italic") format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticaitalic-webfont.eot");
  src: url("../fonts/helveticaitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaitalic-webfont.woff2") format("woff2"), url("../fonts/helveticaitalic-webfont.woff") format("woff"), url("../fonts/helveticaitalic-webfont.svg#helveticaneuecyritalic") format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticalight-webfont.eot");
  src: url("../fonts/helveticalight-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticalight-webfont.woff2") format("woff2"), url("../fonts/helveticalight-webfont.woff") format("woff"), url("../fonts/helveticalight-webfont.svg#helveticaneuecyrlight") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticalightitalic-webfont.eot");
  src: url("../fonts/helveticalightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticalightitalic-webfont.woff2") format("woff2"), url("../fonts/helveticalightitalic-webfont.woff") format("woff"), url("../fonts/helveticalightitalic-webfont.svg#helveticaneuecyrlight_italic") format("svg");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticamedium-webfont.eot");
  src: url("../fonts/helveticamedium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticamedium-webfont.woff2") format("woff2"), url("../fonts/helveticamedium-webfont.woff") format("woff"), url("../fonts/helveticamedium-webfont.svg#helveticaneuecyrmedium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticamediumitaicl-webfont.eot");
  src: url("../fonts/helveticamediumitaicl-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticamediumitaicl-webfont.woff2") format("woff2"), url("../fonts/helveticamediumitaicl-webfont.woff") format("woff"), url("../fonts/helveticamediumitaicl-webfont.svg#helveticaneuecyrmedium_italic") format("svg");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticaregular-webfont.eot");
  src: url("../fonts/helveticaregular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaregular-webfont.woff2") format("woff2"), url("../fonts/helveticaregular-webfont.woff") format("woff"), url("../fonts/helveticaregular-webfont.svg#helveticaneuecyrroman") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticathin-webfont.eot");
  src: url("../fonts/helveticathin-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticathin-webfont.woff2") format("woff2"), url("../fonts/helveticathin-webfont.woff") format("woff"), url("../fonts/helveticathin-webfont.svg#helveticaneuecyrthin") format("svg");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticathinitalic-webfont.eot");
  src: url("../fonts/helveticathinitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticathinitalic-webfont.woff2") format("woff2"), url("../fonts/helveticathinitalic-webfont.woff") format("woff"), url("../fonts/helveticathinitalic-webfont.svg#helveticaneuecyrthin_italic") format("svg");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticaultralight-webfont.eot");
  src: url("../fonts/helveticaultralight-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaultralight-webfont.woff2") format("woff2"), url("../fonts/helveticaultralight-webfont.woff") format("woff"), url("../fonts/helveticaultralight-webfont.svg#helveticaneuecyrultralight") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Helvetica';
  src: url("../fonts/helveticaultralightitalic-webfont.eot");
  src: url("../fonts/helveticaultralightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/helveticaultralightitalic-webfont.woff2") format("woff2"), url("../fonts/helveticaultralightitalic-webfont.woff") format("woff"), url("../fonts/helveticaultralightitalic-webfont.svg#helveticaneuecyrultraLtIt") format("svg");
  font-weight: 200;
  font-style: italic; }

.header__menu .menu {
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase; }

.header__menu .menu li a {
  background-color: #666;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  color: #fff;
  display: block;
  text-decoration: none;
  line-height: 40px;
  height: 40px; }

.header__menu .menu li a.min-lev {
  background: #2bab61;
  background: -moz-linear-gradient(left, #2bab61 0%, #15893d 100%);
  background: -webkit-linear-gradient(left, #2bab61 0%, #15893d 100%);
  background: linear-gradient(to right, #2bab61 0%, #15893d 100%); }

.header__menu .menu li a.min-middle-lev {
  background: #f7a302;
  background: -moz-linear-gradient(left, #f7a302 0%, #f28001 100%);
  background: -webkit-linear-gradient(left, #f7a302 0%, #f28001 100%);
  background: linear-gradient(to right, #f7a302 0%, #f28001 100%); }

.header__menu .menu li a.middle-lev {
  background: #32a4d9;
  background: -moz-linear-gradient(left, #32a4d9 0%, #1a81c6 100%);
  background: -webkit-linear-gradient(left, #32a4d9 0%, #1a81c6 100%);
  background: linear-gradient(to right, #32a4d9 0%, #1a81c6 100%); }

.header__menu .menu li a.hight-lev {
  background: #9055c9;
  background: -moz-linear-gradient(left, #9055c9 0%, #6a33af 100%);
  background: -webkit-linear-gradient(left, #9055c9 0%, #6a33af 100%);
  background: linear-gradient(to right, #9055c9 0%, #6a33af 100%); }

.header__menu .menu li a:hover {
  background: #c83b18; }

/** Max-width 991px **/
@media (max-width: 991px) {
  .social > a {
    margin: 0 8px; }
  .item {
    padding: 15px; }
  .header__menu {
    margin-top: 15px; }
  .header__menu .menu {
    flex-wrap: wrap; }
  .header__menu .menu li {
    margin-bottom: 15px;
    width: 45%; }
  .item__image {
    margin: -15px -15px 25px; } }

@media (max-width: 768px) {
  .search-mobile {
    color: #c83b18;
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    text-transform: uppercase;
    padding: 23px 0;
    height: 65px; }
  .search-mobile .icon-search {
    font-size: 15px;
    margin-right: 4px; }
  .search-mobile a {
    color: #c83b18;
    text-decoration: none; }
  .header__mod-search {
    background: #fff;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 15px;
    right: 15px;
    top: -100%;
    opacity: 0;
    transition: all .3s ease;
    padding: 10px;
    z-index: 10; }
  .header__mod-search.visible {
    top: 30px;
    opacity: 1;
    transition: all .3s ease; }
  .header__mod-search .icon-close {
    cursor: pointer;
    color: #444;
    font-size: 24px;
    position: absolute;
    right: 0;
    top: -24px;
    background: #fff; }
  .social.footer__social {
    text-align: center; }
  .full__body {
    padding: 15px; }
  .footer__counter {
    text-align: center;
    margin-top: 10px; }
  .footer__copyright {
    text-align: center; } }

@media (max-width: 575px) {
  .header {
    margin-bottom: 20px; }
  .header__menu .menu li {
    width: 100%; }
  .header__bottom {
    background-repeat: repeat; }
  .item__slovar {
    margin-right: 5px; }
  a.item__readmore .icon-right-arrow {
    margin-left: 4px; }
  .item__tags > a {
    margin-bottom: 5px; }
  .item__tags > a:not(:last-child) {
    margin-right: 5px; }
  .full__audiopl {
    margin-bottom: 20px; }
  .full__hits {
    margin-bottom: 15px; }
  .pagination-list > li.pagen-prev a, .pagination-list > li.pagen-first a, .pagination-list > li.pagen-next a, .pagination-list > li.pagen-end a {
    justify-content: center; } }

@media (max-width: 360px) {
  .pagination.items-nav .pagination-list > li:first-child {
    margin: 0 0 15px; }
  .pagination.items-nav .pagination-list > li:last-child {
    margin: 0; }
  .pagination.items-nav .pagination-list > li a {
    justify-content: center; } }

/** ====== Min-Width =======
=================================== **/
@media (min-width: 576px) {
  .header {
    margin-bottom: 70px; }
  .header__bottom {
    background-repeat: repeat-x; }
  .header__bottom > .container {
    height: 185px; }
  .item__slovar {
    margin-right: 30px; }
  a.item__readmore .icon-right-arrow {
    margin-left: 8px; }
  .item__tags > a:not(:last-child) {
    margin-right: 10px; }
  .full__footer {
    display: flex; }
  .footer__copyright p {
    display: inline-block;
    text-align: left; }
  .pagination-list > li.pagen-next {
    margin-left: 15px; }
  .pagination-list > li.pagen-prev {
    margin-right: 15px; } }

@media (min-width: 769px) {
  .header__mod-search {
    padding-top: 15px;
    padding-bottom: 15px; }
  .full__body {
    padding: 30px 40px; }
  .footer__counter {
    text-align: right; } }

@media (min-width: 990px) {
  .header {
    height: 250px; }
  .social > a {
    margin: 0 12px; }
  .item {
    padding: 25px 30px; }
  .header__menu {
    margin-top: 40px; }
  .header__menu .menu li {
    width: 25%; }
  .header__menu .menu li:not(:last-child) {
    margin-right: 30px; } }

/** Min-width 992px **/
@media (min-width: 992px) {
  #page {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh; }
  .contentleft, .contentmiddle {
    position: relative; }
  .contentleft {
    left: 33.3333%; }
  .contentmiddle {
    left: 25%; }
  .left.sidebar {
    position: relative;
    left: -66.6667%; }
  .left.sidebar.bothSidebars {
    left: -50%; }
  .item__image {
    margin: -25px -30px 25px; } }

/** Hidden elements **/
.invisible {
  visibility: hidden !important; }

.hidden-xs-up {
  display: none !important; }

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

svg:not(:root) {
  overflow: hidden; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/** Заголовки **/
h1, h2, h3, h4, h5, h6 {
  color: #444444;
  font-weight: 600;
  margin: 20px 0;
  line-height: 1.2; }

h1, .componentheading {
  /* 30px */
  font-size: calc(1.65rem + 2 * (100vw - 320px) / 880);
  margin: 0 0 30px; }

h2 {
  font-size: 27px; }

h3 {
  font-size: 24px; }

h4 {
  font-size: 20px; }

h5 {
  font-size: 18px; }

h6 {
  font-size: 16px; }

/** Параграф **/
p {
  color: #444;
  margin: 10px 0;
  font-size: 14px;
  line-height: 1.45; }

.textblock p {
  color: #444;
  font-size: 15px;
  line-height: 1.4;
  margin: 15px 0; }

/** Ссылки **/
a {
  color: #3498c6; }

a:hover {
  text-decoration: none; }

a:focus {
  outline: none; }

/** Списки **/
ul, ol {
  list-style: none;
  margin: 0;
  padding: 0; }

.textblock ol, .textblock ul {
  color: #444;
  font-size: 15px;
  margin-left: 30px;
  line-height: 1.4; }

.textblock ul li, .textblock ol li:not(:last-child) {
  margin-bottom: 10px; }

.textblock ul li::before {
  content: "—";
  margin-right: 8px; }

.textblock ul.checklist, .textblock ol.checklist {
  list-style: none; }

.textblock ul.checklist li::before, .textblock ol.checklist li::before {
  content: "\f00c";
  display: block;
  font: normal normal normal 15px/1 FontAwesome;
  margin-right: 10px;
  margin-top: 4px;
  float: left; }

.textblock {
  font-size: 14px; }

.small-text {
  font-size: 12px; }

blockquote {
  border-left: 2px solid #cccccc;
  padding-left: 30px;
  margin: 30px 0 30px 30px; }

.content blockquote p {
  color: #333333;
  font: italic 15px/1.6 'Droid Serif', sans-serif; }

/* ======== Footer ======
==================================== */
#footer {
  margin-top: 30px;
  min-width: 320px; }

.footer-top {
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  padding-top: 25px;
  padding-bottom: 25px; }

.logo-footer {
  display: inline-block;
  text-decoration: none; }

.footer-top__contact {
  overflow: hidden; }

.footer-top__contact p {
  color: #666;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.55;
  margin: 0; }

.footer-top__contact p span {
  font-weight: 500; }

/** ============================

 * ZM-IT - разработка сайтов
 * http://zm-it.ru
 * E-mail: info@zm-it.ru
 * Skype: zaur-mag11
 * Phone: +79064495544

===================================== **/
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-height: 100%;
  position: relative; }

*, *::after, *::before {
  box-sizing: inherit; }

body {
  background-color: #f9f9f9;
  font-family: 'Helvetica', sans-serif;
  margin: 0;
  padding: 0; }

/* ======== Page ======
=================================== */
#page {
  margin: 0 auto;
  text-align: left;
  min-width: 320px;
  max-width: 1920px; }

/** Header **/
.header__top {
  background: #f9f9f9;
  box-shadow: 0px 0px 5px 0px #000000; }

.header__logo {
  padding: 7px 0; }

.header__bottom {
  background: url(../images/header.jpg) center top; }

.header__bottom > .container {
  padding-top: 30px; }

.header__site-slogan {
  color: #e5e5e5;
  /* font-size: 40px;*/
  font-size: calc(1.5rem + 6.4 * (100vw - 320px) / 640);
  font-weight: 300;
  text-align: center;
  max-width: 560px;
  margin: 0 auto; }

.slogan-bold {
  color: #fefefe;
  font-weight: 600;
  text-shadow: 0px 0px 50px #000000; }

/** Search **/
.header__search-form {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  padding: 1px;
  height: 35px; }

input.search-input {
  background: transparent url(../images/search-icon.png) 12px 50% no-repeat;
  border: none;
  padding-left: 40px;
  height: 30px;
  width: 100%; }

input.search-input::-webkit-input-placeholder {
  color: #666;
  font-style: italic; }

input.search-input::-moz-placeholder {
  color: #666;
  font-style: italic; }

/** Social icons **/
.social {
  font-size: 20px;
  padding: 20px 0;
  text-align: right; }

.social > a {
  color: rgba(0, 0, 0, 0.4);
  text-decoration: none; }

.social > a:hover {
  color: #c83b18; }

/* ====== Main container =======
=========================================== */
.maincontainer {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

/* ======== Components ======
==================================== */
/* ======== Items teaser ====== */
/* Levels color */
.item__level {
  border-radius: 50%;
  min-width: 17px;
  margin-right: 12px;
  margin-top: 4px;
  height: 17px;
  width: 17px; }

.min-lev {
  background: #2bab61;
  background: -moz-linear-gradient(left, #2bab61 0%, #15893d 100%);
  background: -webkit-linear-gradient(left, #2bab61 0%, #15893d 100%);
  background: linear-gradient(to right, #2bab61 0%, #15893d 100%); }

.min-middle-lev {
  background: #f7a302;
  background: -moz-linear-gradient(left, #f7a302 0%, #f28001 100%);
  background: -webkit-linear-gradient(left, #f7a302 0%, #f28001 100%);
  background: linear-gradient(to right, #f7a302 0%, #f28001 100%); }

.middle-lev {
  background: #32a4d9;
  background: -moz-linear-gradient(left, #32a4d9 0%, #1a81c6 100%);
  background: -webkit-linear-gradient(left, #32a4d9 0%, #1a81c6 100%);
  background: linear-gradient(to right, #32a4d9 0%, #1a81c6 100%); }

.hight-lev {
  background: #9055c9;
  background: -moz-linear-gradient(left, #9055c9 0%, #6a33af 100%);
  background: -webkit-linear-gradient(left, #9055c9 0%, #6a33af 100%);
  background: linear-gradient(to right, #9055c9 0%, #6a33af 100%); }

/*****/
.category__heading {
  position: relative;
  text-align: center;
  margin: 0 0 30px; }

.category__heading::before {
  border-top: 2px dotted #444;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0; }

.category__title {
  color: #444;
  margin: 0;
  font-size: 24px; }

.category__title span {
  background: #f9f9f9;
  padding: 0 10px;
  position: relative;
  z-index: 1; }

/* Colors heading category */
.category__heading.category__min-lev::before {
  border-color: #2bab61; }

.category__heading.category__min-middle-lev::before {
  border-color: #f7a302; }

.category__heading.category__min-lev .category__title {
  color: #2bab61; }

.category__heading.category__min-middle-lev .category__title {
  color: #f7a302; }

.category__heading.category__middle-lev::before {
  border-color: #32a4d9; }

.category__heading.category__middle-lev .category__title {
  color: #32a4d9; }

.category__heading.category__hight-lev::before {
  border-color: #9055c9; }

.category__heading.category__hight-lev .category__title {
  color: #9055c9; }

/*** End color heading ***/
.teaser > .row > [class^="col-"] {
  margin-bottom: 30px; }

.item {
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: 100%; }

.item__image img {
  display: block;
  width: 100%; }

.item__header {
  align-items: flex-start;
  display: flex;
  margin-bottom: 20px; }

.item__title {
  font-size: 20px;
  font-weight: 500; }

.item__title a {
  color: #444444;
  text-decoration: none; }

.item__title a:hover {
  color: #c83b18;
  text-decoration: underline; }

.item__wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.item__tags {
  font-size: 12px;
  margin-bottom: 15px; }

.item__tags > a {
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #666;
  display: inline-block;
  text-decoration: none;
  padding: 3px 8px;
  margin-bottom: 8px; }

.item__tags > a:hover {
  background: #666;
  color: #ffffff; }

.item__anons {
  color: #444444;
  font-size: 14px;
  line-height: 20px; }

.item__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.item__hits {
  color: #666;
  font-size: 14px; }

.item__hits .icon-eye {
  vertical-align: top;
  margin-right: 5px; }

.icon-slovar {
  background: url(../images/slovar.png) no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  width: 20px;
  height: 23px; }

.item__more {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600; }

.item__slovar {
  color: #c83b18;
  text-decoration: none; }

a.item__slovar span {
  border-bottom: 1px dotted #c83b18;
  display: inline-block;
  vertical-align: middle; }

a.item__slovar:hover span {
  border-bottom-color: #ffffff; }

a.item__readmore {
  color: #32a4d9;
  text-decoration: none; }

.category__min-lev a.item__readmore {
  color: #2bab61; }

.category__min-middle-lev a.item__readmore {
  color: #f7a302; }

.category__hight-lev a.item__readmore {
  color: #9055c9; }

a.item__readmore .icon-right-arrow {
  font-size: 14px; }

.teaser a.item__readmore:hover {
  color: #444; }

/* ======== Item full ====== */
.full__lev-name {
  color: #444;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  position: relative;
  margin-bottom: 20px; }

.full__lev-name::before {
  border-top: 2px dotted #444;
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 1; }

.full__lev-name span {
  background: #f9f9f9;
  display: inline-block;
  padding: 0 10px;
  position: relative;
  z-index: 2; }

/* Colors heading full */
.min-lev-name.full__lev-name::before {
  border-color: #2bab61; }

.min-lev-name.full__lev-name {
  color: #2bab61; }

.min-middle-lev-name.full__lev-name::before {
  border-color: #f7a302; }

.min-middle-lev-name.full__lev-name {
  color: #f7a302; }

.middle-lev-name.full__lev-name::before {
  border-color: #32a4d9; }

.middle-lev-name.full__lev-name {
  color: #32a4d9; }

.hight-lev-name.full__lev-name::before {
  border-color: #9055c9; }

.hight-lev-name.full__lev-name {
  color: #9055c9; }

/******/
.full__item {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2); }

.full__image-video img {
  display: block; }

.full__header {
  align-items: flex-start;
  display: flex;
  margin-bottom: 30px; }

.full__header .item__level {
  margin-top: 6px; }

.full__title {
  margin: 0; }

.full__audiopl audio {
  width: 100%;
  height: 35px; }

.full_text {
  margin-top: 40px;
  word-wrap: break-word; }

.full__slovar {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%; }

.full__slovar .icon-slovar {
  background: url(../images/slovar-white.png) center no-repeat;
  vertical-align: middle; }

.full__slovar span {
  border-bottom: 1px dotted #ffffff; }

.full__tags {
  font-size: 14px;
  margin-bottom: 40px; }

.full__footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 0 0;
  justify-content: space-between; }

.full__hits {
  color: #3e3e40;
  font-size: 14px;
  font-weight: 600; }

.full__hits .icon-eye {
  font-size: 16px;
  margin-right: 5px;
  vertical-align: top; }

.full__share {
  display: flex;
  align-items: center; }

.full__share-title {
  color: #3e3e40;
  font-size: 14px;
  margin-right: 8px; }

/** ====== Search component ======== **/
.search_buttons_block {
  border-bottom: 2px solid #32a4d9;
  margin: 0 0 30px;
  padding: 0 0 30px;
  display: flex;
  min-width: 260px; }

.search_buttons_block .btn {
  padding: 0 20px; }

#searchForm #search-searchword {
  width: calc(11rem + 900 * (100vw - 320px) / 880);
  min-width: 176px; }

.phrases {
  border-bottom: 1px solid #c8c8c8;
  border-top: 1px solid #c8c8c8;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin: 0 0 20px; }

.phrases-box {
  margin-right: 15px; }

.phrases-box label {
  color: #383838;
  margin-right: 15px;
  font-size: 14px; }

.phrases-box .jq-radio {
  margin-right: 5px; }

.phrases-box input[type="radio"] {
  margin-right: 5px; }

.ordering-box {
  font-size: 14px;
  color: #383838; }

@media (max-width: 768px) {
  .phrases {
    flex-direction: column; }
  .phrases > div:not(:last-child) {
    margin-bottom: 15px; } }

#searchForm .chzn-results {
  margin: 0 4px 4px 0;
  font-size: 14px; }

#searchForm .chzn-results li {
  padding: 5px 10px; }

#searchForm .ordering {
  margin-right: 5px;
  line-height: 24px; }

.result_block {
  border-bottom: 1px dotted #999;
  margin: 0 0 20px;
  padding: 0 0 20px;
  font-size: 14px; }

.result-title {
  font-size: 16px;
  margin-bottom: 5px; }

.result-text {
  color: #383838; }

.result-category {
  color: #999;
  margin-bottom: 5px; }

/* ======== Pagination ====== */
.pagination {
  margin-top: 30px; }

.pagination-list {
  color: #777;
  font-size: 0;
  list-style: none;
  text-align: center;
  font-size: 14px; }

.pagination-list > li {
  vertical-align: top;
  line-height: 30px;
  display: inline-block;
  margin: 0 7.5px 7.5px; }

.pagination-list > li a, .pagination-list > li > span, .pagination-list > li.disabled span {
  border-radius: 50%;
  background-color: #f2f2f2;
  background: -webkit-linear-gradient(135deg, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0.042) 0%, transparent 100%);
  background: -moz-linear-gradient(135deg, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0.042) 0%, transparent 100%);
  background: -o-linear-gradient(135deg, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0.042) 0%, transparent 100%);
  background: -ms-linear-gradient(135deg, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0.042) 0%, transparent 100%);
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.042) 0%, rgba(0, 0, 0, 0.042) 0%, transparent 100%);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  display: block;
  height: 30px; }

.pagination-list > li.pagen-li a, .pagination-list > li.pagen-li > span, .pagination-list > li.active > span {
  width: 30px; }

.pagination-list > li a {
  color: #777;
  text-decoration: none; }

.pagination-list > li.active span, .pagination-list > li a:hover {
  background: #c83b18;
  background: -moz-linear-gradient(left, #c83b18 0%, #e85e2c 100%);
  background: -webkit-linear-gradient(left, #c83b18 0%, #e85e2c 100%);
  background: linear-gradient(to right, #c83b18 0%, #e85e2c 100%);
  color: #fff; }

.pagination-list > li.disabled span {
  border-radius: 20px;
  padding: 0 20px; }

.pagination-list > li.pagen-next a, .pagination-list > li.pagen-end a, .pagination-list > li.pagen-prev a, .pagination-list > li.pagen-first a {
  border-radius: 20px;
  background: #c83b18;
  background: -moz-linear-gradient(left, #c83b18 0%, #e85e2c 100%);
  background: -webkit-linear-gradient(left, #c83b18 0%, #e85e2c 100%);
  background: linear-gradient(to right, #c83b18 0%, #e85e2c 100%);
  color: #fff;
  padding: 0 20px; }

.pagination-list > li.disabled span, .pagination-list > li.pagen-next a, .pagination-list > li.pagen-end a, .pagination-list > li.pagen-prev a, .pagination-list > li.pagen-first a {
  display: flex;
  align-items: center; }

.pagination-list .icon-back {
  margin-right: 5px; }

.pagination-list .icon-next {
  margin-left: 5px; }

/* ======== Footer ======
==================================== */
#footer > .container {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px; }

#footer > .container::before {
  background-color: rgba(0, 0, 0, 0.1);
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  height: 1px; }

.footer__copyright p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px; }

.footer__copyright p a {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  text-decoration: none; }

.footer__copyright p a:hover {
  color: #c83b18;
  text-decoration: underline; }

.footer__copyright-dvr {
  display: inline-block;
  margin: 0 25px; }

/** Fix для браузера ИЕ11 **/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #page {
    display: block; } }
